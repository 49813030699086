<template>

  <section v-if="errored">
    <p>{{ $t('general.try_back_later') }}</p>
  </section>

  <spinner v-else-if="loading"></spinner>
  <section v-else>
    <div>
      <span class="tw-flex tw-items-center ">
        <plus-circle-icon v-if="!this.isEdit" class="tw-w-6 tw-h-6" />
        <edit2-icon v-if="this.isEdit" class="tw-w-6 tw-h-6" />
        <span class="tw-ml-2 tw-font-semibold">{{ buttonText }}</span>
      </span>

      <div v-if="!loading">
        <form ref="form" novalidate="false" @submit.prevent="handleSubmit" @change="validateForm()" method="POST"
              class="tw-grid tw-grid-cols-3 tw-gap-x-8">

            <div class="tw-mt-4 tw-col-span-3" v-if="addressTypes">
                <div 
                  v-for="(type,index) in addressTypes.filter(item => item.id != homeAddressTypeId)" :key="index"
                  class="tw-grid tw-grid-cols-3 tw-mt-2 tw-items-center tw-flex tw-justify-items-stretch
                   tw-rounded-lg tw-border tw-h-16 
                   tw-cursor-pointer" 
                  @click="selectType(type.id)"
                  :class="item.type_id == type.id ? 'tw-bg-red-100 tw-border-red':'tw-border-gray-n1 tw-bg-gray-n0'">
                    <div class="tw-col-span-1 tw-justify-self-start"><home-icon class="tw-w-6 tw-w-6 tw-ml-3" /></div>
                    <div class="tw-col-span-1 tw-font-semibold tw-justify-self-center">{{type.name}}</div>
                    <div class="tw-col-span-1 tw-justify-self-end">
                      <input class="tw-mr-3" 
                      :class="item.type_id == type.id ? 'tw-bg-red':'tw-bg-gray-n2'"
                      id="default-radio-2" type="radio" v-model="item.type_id" :value="type.id" 
                      name="default-radio">
                    </div>
                </div>
                <ml-error-msg :msg="errors['type_id']"/>
            </div>

            <div class="tw-mt-4 tw-col-span-3">
              <ml-label required for="address_1">{{ $t('vendor.data.address') }}</ml-label>
              <div class="tw-mt-2">
                <ml-input-text v-model="item.address1"
                              :placeholder="address1_placeholder" id="address_1" name="address_1" required/>
                <ml-error-msg :msg="errors['address1']"/>
              </div>
            </div>
            
            <div class="tw-mt-4 tw-col-span-3">
              <div class="tw-mt-2">
                <ml-input-text v-model="item.address2"
                              :placeholder="address2_placeholder" id="address_2" name="address_2" required/>
                <ml-error-msg :msg="errors['address2']"/>
              </div>
            </div>

            <div class="tw-mt-4 tw-col-span-2">
              <ml-label required>{{ $t('vendor.data.city_state') }}</ml-label>

              <v-autocomplete
                  class="text-caption remove-bg-fix"
                  dense
                  v-model="selectedCities"
                  :items="cities"
                  :search-input.sync="searchCities"
                  :hide-no-data="!searchCities"
                  :loading="loadingCities"
                  :persistent-hint="false"
                  append-icon=""
                  :rules="[rules.required]"
                  :clearable="true"
                  :hide-selected="false"
                  :deletable-chips="true"
                  @click="clearAreas"
                  color="#2d2d2d"
                  :no-filter="true"
                  :cache-items="false"
                  :placeholder="city_placeholder"
                  autocomplete="new-password"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.description"></v-list-item-title>
                  </v-list-item-content>
                </template>

                <template v-slot:selection="data">
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.description"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <ml-error-msg :msg="errors['city']"/>

            </div>

            <div class="tw-mt-4">
              <ml-label required for="zip">{{ $t('vendor.data.zip') }}</ml-label>
              <div class="tw-mt-2">
                <ml-input-text v-model="item.postal_code" id="zip" name="zip" required/>
                <ml-error-msg :msg="errors['zip']"/>
              </div>
            </div>

            <div class="tw-col-span-2">
              <!-- Phone -->
              <div class="tw-mt-4">
                <ml-label required for="phone">{{ $t('vendor.data.phone') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-input-text v-model="item.phone" id="phone" name="phone" @change="validateForm()"/>
                  <ml-error-msg :msg="errors['phone']"/>
                </div>
              </div>
            </div>

            <!-- Comment -->
            <!--
            <div class="tw-col-span-2">
              
              <div class="tw-mt-4">
                <ml-label for="comment">{{ $t('address.comment') }}</ml-label>
                <div class="tw-mt-2">
                  <ml-textarea v-model="item.comment" id="comment" name="comment"/>
                  <ml-error-msg :msg="errors['comment']"/>
                </div>
              </div>

            </div>
            -->
            <!--
            <div class="tw-col-span-1">

                <ml-label for="address_name">{{ $t('address.address_contact') }}</ml-label>
                <div class="tw-mt-2">
                <ml-input-text v-model="item.name" id="address_name" name="address_name" required/>
                <ml-error-msg :msg="errors['name']" />
                </div>

            </div>
            -->
            <!--
            <div class="tw-col-span-2">
              <v-checkbox
                  v-model="item.default_address"
                  :label="$t('address.save_as_primary')"
              ></v-checkbox>
            </div>
            -->

            <div class="tw-mt-4 tw-col-span-2">
              <button type="submit"
                      class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-transparent tw-text-base tw-font-medium tw-rounded-xl tw-text-white tw-bg-black focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-black">
                {{ $t('general.save') }}
              </button>
              <slot name="cancelButton" />
            </div>


        </form>

      </div>

    </div>
  </section>

</template>

<script>

import i18n from '@/i18n'
import { mapGetters } from 'vuex'
import MlLabel from "@/components/layout/form/MlLabel";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import MlInputText from "@/components/layout/form/MlInputText";
//import MlTextarea from "@/components/layout/form/MlTextarea";
import { Edit2Icon, PlusCircleIcon , HomeIcon  } from "vue-feather-icons";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    modal: null,
    id: {
      default: null
    },
  },
  components: {
    MlLabel,
    MlErrorMsg,
    MlInputText,
    //MlTextarea,
    Edit2Icon, PlusCircleIcon , HomeIcon 
  },
  middleware: ['auth', 'user'],
  metaInfo() {
    return {
      script: [{
        src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyA5LRbiOg0tXmXao7V80Q71wjUixM_ntbE&libraries=places`,
        async: true,
        defer: true,
        callback: () => this.MapsInit() // will declare it in methods
      }]
    }
  },
  data() {
    return {
      address1_placeholder: i18n.t('vendor.data.address1_placeholder'),
      address2_placeholder: i18n.t('vendor.data.address2_placeholder'),
      city_placeholder: i18n.t('vendor.data.city'),
      lazy: true,
      item: {
        default_address: true,
        type_id: null
      },
      countries: [],
      addressTypes: [],
      searchCities: null,
      loadingCities: false,
      cities: [],
      // Google Maps API
      location: '',
      searchResults: [],
      service: null,
      filterArea: {},
      filterCountry: [],
      selectedCities: [],
      searchGoogle: false,
      // END
      selected: [],
      loading: true,
      errored: false,
      errors: [],
      valid: false,
      rules: {
        required: value => !!value || 'Required.',
        zip1: v => (v || '').length <= 5 || 'A maximum of 5 numbers is allowed',
        zip: v => /^[0-9]{5}([- ]?[0-9]{4})?$/.test(v) || 'U.S. postal code required (ZIP+4 format)'
      },
    }
  },

  mounted() {

    this.fetchAddressTypes();
    this.loading = true;
    if (this.isEdit) {
      this.fetchAddress();
    } else {
      this.loading = false;
    }
  },
  watch: {
    id: function () {
      if (this.id) {
        this.loading = true;
        this.fetchAddress();
      } else {
        this.item = {};
        this.loading = false;
      }

    },
    searchCities(val) {

      if (this.searchCities == null || this.searchCities.length < 3) {
        return
      }

      setTimeout(function () {
        this.service.getPlacePredictions({
          input: val,
          componentRestrictions: {country: ['us','pr']},
          types: ['(regions)']
        }, this.displaySuggestions)
      }.bind(this), 300);

      //console.log("searchCities...");
    },
    selectedCities: function () {

      //console.log("selectedCities");
      if (!this.selectedCities || this.selectedCities === null) {
        this.filterCountry = [];
        return;
      }

      this.filterArea = {
        city_name: this.selectedCities.city_name,
        code: this.selectedCities.code,
        country: this.selectedCities.country,
        google_place_id: this.selectedCities.id

      };
      if (!Number.isInteger(this.selectedCities.id)) this.fetchAreas(this.filterArea);

      this.validateForm();

    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    buttonText() {
      return this.isEdit ? 'Edit personal address' : 'Add an address'
    },
    homeAddressTypeId() {
      if(this.user.home_address.length){
        return this.user.home_address[0].type_id;
      }else{
        return null;
      }
    },
  },

  methods: {
    selectType(id){
      this.item.type_id = id;

    },
    validateForm() {

      //console.log("validate form...");
      this.errors = [];
      let label = '';


      if (!this.item.address1) {
        label = "Address1 required.";
        this.errors.push(label);
        this.errors["address1"] = label;
      }
      if (!this.item.type_id) {
        label = "Address Type required.";
        this.errors.push(label);
        this.errors["type_id"] = label;
      }
      /*
      if (!this.company.address2) {
      label = "Address2 required.";
      this.errors.push(label);
      this.errors["address2"] = label;
      }


      if (!this.item.city) {
      label = "City required.";
      this.errors.push(label);
      this.errors["city"] = label;
      }
      */

      if (this.item.city instanceof Array && this.item.city.length === 0 || !this.item.city) {
        label = "City required.";
        this.errors.push(label);
        this.errors["city"] = label;
      }

      if (!this.item.postal_code) {
        label = "Zip required.";
        this.errors.push(label);
        this.errors["zip"] = label;
      } else if (!this.validZip(this.item.postal_code)) {
        label = "U.S. postal code required (ZIP+4 format).";
        this.errors.push(label);
        this.errors["zip"] = label;
      }


      if (!this.item.phone) {
        label = "Phone required.";
        this.errors.push(label);
        this.errors["phone"] = label;
      } else if (!this.validPhone(this.item.phone)) {
        label = "U.S. phone format required (E.g (308)-135-7895 or 308-135-7895 or 308135-7895 or 3081357895).";
        this.errors.push(label);
        this.errors["phone"] = label;
      }

      if (!this.errors.length) {
        this.valid = true;
        //console.log("Form valid",this.valid);
        return true;
      }

      this.valid = false;
      //console.log("Form valid",this.valid);
      return false;

    },
    validZip: function (v) {
      var re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
      return re.test(v);
    },
    validPhone: function (v) {
      var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return re.test(v);
    },
    clearAreas() {
      console.log("clearAreas...");
      this.MapsInit();
    },
    MapsInit() {
      this.service = new window.google.maps.places.AutocompleteService();
      console.log("MapsInit...");
    },
    displaySuggestions(predictions, status) {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        this.searchResults = []
        return
      }
      //this.searchResults = predictions.map(prediction => prediction.description)
      this.searchResults = predictions.map(prediction => prediction.terms);

      let data = predictions.map(prediction => prediction);

      this.cities = data.map(t => ({

        id: t.place_id,
        description: t.description,
        city_name: t.terms[0].value,
        code: t.terms[1] ? t.terms[1].value : null,
        country: t.terms[2] ? t.terms[2].value : null,
      }));
    },
    async fetchAreas(val) {
      //console.log("fetchAreas...");
      //let query = (val.city_name+'/'+val.code).toString();
      let query = val;

      const params = {
        "filter[name]": query.city_name,
        "google_place_id": query.google_place_id,
        "filter[country_code]": query.code.substring(0, 2),
        //"filter[filter_all]" : query,
        "page": this.$route.query.page
      };

      await this.$http.get('api/areas', {params})
          .then(({data}) => {
            this.cities = data.data;
            this.selectedCities = this.cities[0];
            this.item.state = this.cities[0].country;
            this.item.city = this.cities[0];
          })
          .catch(error => {
            console.log('Error loading cities data: ' + error)
            this.errored = true
          });
    },
    async fetchAddress() {

      if (!this.id) return true;

      let url = "/api/address/" + this.id;
      //`/api/address/${this.$route.params.id}`
      await this.$http.get(url)
          .then(({data}) => {
            this.item = data.data;
            //this.cities = data.data.city;
            this.cities = this.cities.concat(data.data.city);
            this.selectedCities = this.item.city;
          })
          .catch(error => {
            this.errored = true;
            console.log(error.response);
          });

      this.loading = false;

    },
    fetchCountries() {

      this.$http.get('/api/countries')
          .then(({data}) => {
            this.countries = data.data
          })
          .catch(error => {
            this.errored = true;
            console.log(error.response);
          }).finally(() => {
        this.loading = false
      });

    },
    fetchAddressTypes() {

      this.$http.get('/api/address-types')
          .then(({data}) => {
            this.addressTypes = data.data
          })
          .catch(error => {
            this.errored = true;
            console.log(error.response);
          }).finally(() => {
        this.loading = false
      });

    },
    async handleSubmit() {

      if (!this.validateForm()) return;
      //await this.$refs.form.validate();
      if (!this.valid) return;

      this.item.default_address = true;
      
      this.$emit('submitted', {
        item: this.item
      });
      //console.log("submitted");
    },

    errorClass(column) {
      return [
        'form-control',
        this.errors[column] && this.errors[column][0] ? 'is-invalid' : ''
      ]
    }
  }
}
</script>
