<template>
  <div>

    <v-dialog
        v-if="cart"
        v-model="viewMyCart"
        fullscreen
        persistent
        hide-overlay
        transition="slide-x-transition"
        origin="0 0"
        :disabled="cart.items.length === 0"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on"
             class="tw-text-red tw-bg-gray-n0 tw-rounded-2xl tw-px-4 tw-py-3 tw-flex tw-items-center tw-font-sm">
          {{ cart.items.length }}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
               class="tw-ml-2">
            <path
                d="M6.00001 14.6667C6.3682 14.6667 6.66668 14.3682 6.66668 14C6.66668 13.6319 6.3682 13.3334 6.00001 13.3334C5.63182 13.3334 5.33334 13.6319 5.33334 14C5.33334 14.3682 5.63182 14.6667 6.00001 14.6667Z"
                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M13.3333 14.6667C13.7015 14.6667 14 14.3682 14 14C14 13.6319 13.7015 13.3334 13.3333 13.3334C12.9651 13.3334 12.6667 13.6319 12.6667 14C12.6667 14.3682 12.9651 14.6667 13.3333 14.6667Z"
                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
            <path
                d="M0.666656 0.666626H3.33332L5.11999 9.59329C5.18095 9.90022 5.34793 10.1759 5.59169 10.3722C5.83544 10.5684 6.14045 10.6726 6.45332 10.6666H12.9333C13.2462 10.6726 13.5512 10.5684 13.795 10.3722C14.0387 10.1759 14.2057 9.90022 14.2667 9.59329L15.3333 3.99996H3.99999"
                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </template>

      <div class="tw-absolute tw-inset-0 tw-overflow-hidden">
        <div class="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-75 tw-transition-opacity" aria-hidden="true"></div>
        <div class="tw-pointer-events-none tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full tw-pl-10 sm:tw-pl-16">
          <div class="tw-pointer-events-auto tw-w-full tw-ml-auto" style="min-width: 704px;max-width: 704px;">
            <div class="tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll tw-bg-white tw-py-8 tw-shadow-xl">
              <div class="tw-px-4 sm:tw-px-8 tw-pb-8 tw-border-b tw-border-gray-n1">
                <div class="tw-flex tw-items-start tw-justify-between">
                  <h2 class="tw-text-lg tw-font-medium tw-text-black tw-font-semibold tw-flex tw-items-center"
                      id="slide-over-title">
                    <shopping-cart-icon class="tw-w-6 tw-h-6 tw-mr-3"/>
                    {{ $t('cart.shopping_cart') }}
                  </h2>
                  <div class="tw-ml-3 tw-flex tw-h-7 tw-items-center">
                    <button @click="closeCart()" type="button"
                            class="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2">
                      <span class="tw-sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                           stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="tw-relative tw-mt-6 tw-flex-1 tw-px-4 sm:tw-px-8">
                <v-stepper v-model="e1" elevation="0" class="tw-h-full tw-flex tw-flex-col tw-overflow-visible">
                  <v-stepper-header class="tw-shadow-none">

                    <v-stepper-step
                        :complete="e1 > 1"
                        step="1"
                        :color="!valid1 ? 'orange':'black'"
                    >
                      <span class="tw-text-sm">{{ $t('cart.step_1_title') }}</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 2"
                        step="2"
                        :color="!valid2 ? 'orange':'black'"
                    >
                      <span class="tw-text-sm">{{ $t('cart.step_2_title') }}</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        :complete="e1 > 3"
                        step="3"
                        :color="promotions.length > 0 ? 'black':'grey'"
                    >
                      <span class="tw-text-sm">{{ $t('cart.step_3_title') }}</span>
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                        step="4"
                        :color="!valid4 ? 'orange':'black'"
                    >
                      <span class="tw-text-sm">{{ $t('cart.step_4_title') }}</span>
                    </v-stepper-step>

                  </v-stepper-header>

                  <v-stepper-items class="tw-mt-8">
                    <v-progress-linear
                        v-show="loading"
                        indeterminate
                        color="yellow"
                        height="2"
                        class="tw-mb-2"
                    ></v-progress-linear>
                    <v-stepper-content step="1" class="tw-p-0">

                        <div>
                          <div>
                            <h2 class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3">Items in cart</h2>
                            <div v-for="(item,index) in cart.items" :key="index">
                              <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-4 tw-mb-1">
                                <div class="tw-flex">
                                  <div
                                      v-if="(item.product.type.name === 'Product' || item.product.type.name === 'Package')"
                                      :class="{'tw-flex tw-items-center tw-justify-center tw-bg-gray-n1 tw-rounded-lg' : !item.product.image}"
                                      class="tw-flex-grow-0 tw-flex-shrink-0 tw-mr-4"
                                      style="flex-basis: 160px; height: 100px">
                                    <gallery v-if="item.product.image" :thumb-only="true" :show-border="false"
                                             :images="[{src: item.product.image}]"
                                             :id="'offer-gallery' + item.product.id" :limit="1" thumbWrapperClass=""
                                             :gallery-setup="{}" default-image-class="tw-h-26"/>
                                    <div v-else>
                                      <image-icon class="tw-w-14 tw-h-14 tw-text-gray-n2"/>
                                    </div>
                                  </div>
                                  <div class="tw-flex-grow tw-flex tw-flex-col">
                                    <div class="tw-flex tw-items-center">
                                      <div class="tw-leading-relaxed tw-font-semibold tw-text-black tw-mr-auto">
                                        {{ item.product.name }}
                                      </div>
                                      <span @click="removeItem(item.id)" :disabled="paymentProcessing  || loadingTax || loading"
                                            class="tw-cursor-pointer tw-text-gray-n2 tw-text-xs tw-inline-flex tw-items-center">
                                        Delete
                                        <trash2-icon class="tw-w-3 tw-h-3 tw-ml-3"/>
                                      </span>
                                    </div>
                                    <div>
                                      <div
                                          v-if="item.product.type.name === 'Package' || item.product.type.name === 'Product'"
                                          class="tw-text-xs tw-text-gray-n4">
                                           <span v-if="item.product.type.name === 'Package'">
                                            {{item.product.type.name}} {{ $t('vendor.offer.price') }}: {{ item.package_price_without_discount | currency }}
                                            | {{ $t('vendor.offer.discount') }} ({{ item.product.discount}}%)
                                            | Tax{{item.is_item_taxable ? (item.tax_synced ? '':'(Panding tax validation)'):''}} {{ item.package_tax_amount | currency }} | {{ item.package_price_with_discount | currency }}
                                          </span>
                                           <span v-if="item.product.type.name === 'Product'">
                                            {{item.product.type.name}} {{ $t('vendor.offer.price') }}: {{ item.item_total_price_without_tax | currency }}
                                            | {{ $t('vendor.offer.discount') }} ({{ item.product.discount}}%)
                                            | Tax{{item.is_item_taxable ? (item.tax_synced ? '':'(Panding tax validation)'):''}} {{ item.package_tax_amount | currency }} | {{ item.item_total_price | currency }}
                                          </span>
                                      </div>
                                    </div>
                                    <div v-if="1==2" class="tw-text-xs tw-ml-auto tw-mt-auto tw-leading-relaxed">
                                      Taxable {{item.is_item_taxable ? 'Yes':'No'}} | Tax{{item.is_item_taxable ? (item.tax_synced ? '':'(Panding tax validation)'):''}} {{ item.package_tax_amount | currency }} | {{ item.package_price_with_discount | currency }}
                                    </div>
                                    <!-- Items included in package or product -->
                                    <div v-if="item.packageItems.length" class="tw-text-xs" >

                                     <span class="tw-leading-relaxed tw-font-light tw-text-black tw-mr-auto tw-text-gray-n2">
                                       Include: {{item.packageItems.map(x => x.product.name).toString()}}
                                       <span v-if="item.product.type.name !== 'Package'">
                                         | Tax: {{item.packageItems.map(x => Number(x.tax_amount)).reduce((p, c) => p + c,0) | currency}}
                                         | Price: {{item.packageItems.map(x => Number(x.package_price_with_discount)).reduce((p, c) => p + c,0) | currency}}
                                       </span>
                                     </span>
                                      <!--
                                        <div v-for="(packageItem,index) in item.packageItems" :key="index">

                                       <                                          <span class="tw-leading-relaxed tw-font-light tw-text-black tw-mr-auto"
                                           :class="item.product.type.name === 'Package' ? 'tw-text-gray-n2':''">
                                             {{ packageItem.product.name }} (<span class="tw-text-xs tw-italic">{{ packageItem.product.type.name }}</span>)
                                             <span>| {{packageItem.package_price_with_discount | currency}}</span>
                                             <span>| Tax: {{packageItem.is_item_taxable ? (packageItem.tax_synced ? '':'(Panding tax validation)'):''}} {{ packageItem.tax_amount | currency }}</span>
                                             <span>| {{packageItem.package_total_price | currency}}</span>
                                           </span>

                                      </div>
                                      -->
                                    </div>                                    
                                    <div class="tw-text-xs tw-ml-auto tw-text-red tw-mt-auto tw-leading-relaxed" v-if="item.is_item_taxable && !item.tax_synced">
                                      Tax will be recalcualted once you enter or select wedding address, you can do it in next step.
                                    </div>                                    
                                    <div class="tw-ml-auto tw-mt-auto tw-font-semibold tw-leading-relaxed">
                                      Total {{ item.package_total_price | currency }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!--
                          <div class="tw-mt-6 tw-text-xs">
                            <h2 class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3 tw-text-base">Order info</h2>
                            <div class="tw-space-y-3 tw-mb-3 tw-pb-3 tw-border-b tw-border-gray-n0">
                              <div class="tw-flex tw-items-center tw-justify-between">
                                <span class="tw-text-gray-n3">Items subtotal</span>
                                <span>{{ cart.total_price_without_tax | currency }}</span>
                              </div>
                              <div class="tw-flex tw-items-center tw-justify-between">
                                <span class="tw-text-gray-n3">Tax amount</span>
                                <span>{{ cart.total_tax | currency }}</span>
                              </div>
                            </div>
                            <div class="tw-flex tw-text-black tw-items-center tw-justify-between">
                              <span class="tw-text-xs">Total</span>
                              <span class="tw-text-base tw-leading-relaxed tw-font-semibold">{{ cart.total_price | currency }}</span>
                            </div>
                          </div>
                          -->

                        </div>

                    </v-stepper-content>

                    <v-stepper-content step="2" class="tw-p-0">

                      <!-- Person -->
                      <div class="tw-border tw-border-1 tw-rounded tw-border-gray-n1 tw-p-4 tw-mt-2 tw-mb-2">
                        <div class="tw-mt-2">
                          <span class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3 tw-mt-6 tw-text-base">{{$t('cart.contact_info')}}</span>
                        </div>

                        <div v-if="(errors.length && emailExist)" class="tw-border tw-border-1 tw-rounded-xl tw-border-gray-n1 tw-p-4 tw-mt-2 tw-mb-2">
                            <router-link class="tw-text-black tw-mt-3" :to="{ name: 'login' }">
                              <span class="tw-text-gray-n3">{{ $t('auth.already_have_account') }}</span>
                              {{ $t('auth.signin') }}
                            </router-link>

                            <div class="tw-cursor-pointer tw-mt-3 hover:tw-underline hover:tw-underline-offset-1" @click="nextGuest">
                              <span class="tw-text-gray-n3">{{ $t('auth.dont_have_account') }}</span>
                              {{ $t('auth.continue_as_guest') }}
                            </div>
                        </div>

                        <ml-alert v-if="errors.length > 0 && !emailExist" class="tw-mb-2">
                          <template v-slot:title>
                            The given data was invalid.
                          </template>
                              <template v-slot:msg>
                                {{errors}}
                              </template>
                        </ml-alert>

                        <!-- If Guest-->
                        <div v-if="guestGhost">
                          <div>
                            <div class="tw-grid tw-grid-cols-2 tw-gap-2">
                              <div>
                                <ml-label required for="order_person_firstname">{{ $t('cart.person.firstname') }}</ml-label>
                                <div class="tw-mt-2">
                                  <ml-input-text v-model="order.person.firstname" id="order_person_firstname" name="order_person_firstname" required/>
                                  <ml-error-msg :msg="errors['firstname']" />
                                </div>
                              </div>
                              <div>
                                <ml-label required for="order_person_lastname">{{ $t('cart.person.lastname') }}</ml-label>
                                <div class="tw-mt-2">
                                  <ml-input-text v-model="order.person.lastname" id="order_person_lastname" name="order_person_lastname" required/>
                                  <ml-error-msg :msg="errors['lastname']" />
                                </div>
                              </div>
                            </div>
                            <div class="tw-grid tw-grid-flow-row tw-auto-rows-max tw-gap-2">
                              <div>
                                <ml-label required for="order_person_email">{{ $t('auth.email') }}</ml-label>
                                <div class="tw-mt-2">
                                  <ml-input-text v-model="order.person.email" id="order_person_email" name="order_person_email" required/>
                                  <ml-error-msg :msg="errors['email']" />
                                </div>
                              </div>
                              <div>
                                <ml-label required for="order_person_email_confirmation">{{ $t('auth.email_confirm_type') }}</ml-label>
                                <div class="tw-mt-2">
                                  <ml-input-text v-model="order.person.email_confirmation" id="order_person_email_confirmation" name="order_person_email_confirmation" required/>
                                  <ml-error-msg :msg="errors['email_confirmation']" />
                                </div>
                              </div>
                              <div class="tw-mt-2 tw-mb-2">
                                <div>
                                  <input v-model="order.person.create_account" id="create_account" type="checkbox" value="1"
                                         class="w-4 h-4 tw-text-red tw-bg-gray-n tw-ring-1 tw-ring-black rounded tw-border-black focus:tw-ring-red
                                    dark:focus:tw-ring-red-100 dark:tw-ring-offset-gray-n1 focus:tw-ring-2 dark:tw-bg-gray-n1 dark:tw-border-gray-n2">
                                  <label class="tw-ml-2 tw-text-xs tw-leading-relaxed tw-text-black"
                                         for="create_account">{{ $t('auth.create_account') }}</label>
                                </div>
                              </div>
                            </div>
                            <div class="tw-grid tw-grid-flow-row tw-auto-rows-max tw-gap-2">
                              <!-- Password -->
                              <div v-if="order.person.create_account">
                                <div class="tw-mt-6">
                                  <label for="password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                                    Password <span class="tw-text-red">*</span>
                                  </label>
                                  <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
                                    <input v-model="order.person.password" id="password" name="password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="current-password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                                    <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
                                      <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
                                    </a>
                                  </div>
                                  <ml-error-msg :msg="errors['password']" />
                                </div>

                                <div class="tw-mt-6">
                                  <label for="confirm_password" class="tw-mb-0 tw-block tw-text-sm tw-font-semibold tw-text-gray-n3 tw-leading-relaxed">
                                    {{ $t('auth.confirm_password') }} <span class="tw-text-red">*</span>
                                  </label>
                                  <div class="tw-mt-2 tw-relative tw-rounded-xl tw-border tw-border-gray-n1">
                                    <input v-model="order.person.password_confirmation" id="confirm_password" name="confirm_password" :type="showPasswordValue ? 'text' : 'password'" autocomplete="confirm_password" required class="tw-appearance-none tw-bg-transparent tw-block tw-w-full tw-px-4 tw-py-3 tw-border-none tw-placeholder-gray-n1 focus:tw-outline-none focus:tw-ring-black focus:border-black sm:tw-text-sm">
                                    <a class="tw-absolute tw-inset-y-0 tw-right-0 tw-pr-3 tw-flex tw-items-center" @click.prevent.stop="togglePassword" @mouseleave="hidePassword">
                                      <eye-icon class="tw-w-4 tw-h-4 tw-text-gray-n3" />
                                    </a>
                                  </div>
                                  <ml-error-msg :msg="errors['password_confirmation']" />
                                </div>
                              </div>
                            </div>
                            <div class="tw-grid tw-grid-flow-row tw-auto-rows-max tw-gap-2">
                              <div class="tw-mt-2">
                                <input v-model="order.person.terms_and_conditions" id="terms_and_conditions" type="checkbox" value="1"
                                       class="w-4 h-4 tw-text-red tw-bg-gray-n tw-ring-1 tw-ring-black rounded tw-border-black focus:tw-ring-red
                                      dark:focus:tw-ring-red-100 dark:tw-ring-offset-gray-n1 focus:tw-ring-2 dark:tw-bg-gray-n1 dark:tw-border-gray-n2">
                                <label for="terms_and_conditions" class="tw-ml-2 tw-text-xs tw-leading-relaxed tw-text-black">
                                  By submitting this form, I agree to be bound by the <router-link target="_blank" class="tw-text-red" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
                                  and <router-link target="_blank" class="tw-text-red" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>.
                                </label>
                              </div>
                              <ml-error-msg :msg="errors['terms_and_conditions']" />
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          {{user.name}}
                        </div>
                      </div>

                      <!-- Address -->
                      <div class="tw-border tw-border-1 tw-rounded tw-border-gray-n1 tw-p-4 tw-mt-2 tw-mb-2">
                        <div class="tw-mt-2">
                          <span class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3 tw-mt-6 tw-text-base">{{$t('cart.shipping_address')}}</span>
                          <span class="tw-text-xs tw-text-black tw-ml-2">*Where service is going to take place OR Where goods are being shipped to.</span>
                        </div>
                      
                        <div class="tw-mb-4 tw-mt-2" v-if="!newAddressForm">

                              <ml-alert v-if="!this.order.address" class="tw-mb-2">
                                <template v-slot:title>
                                  You have not selected an address.
                                </template>
                                <template v-slot:msg>
                                  *Please select your address or add a new one in the field below.
                                </template>
                              </ml-alert>
                              <ml-alert v-if="this.order.address && !this.order.address.valid" class="tw-mb-2">
                                <template v-slot:title>
                                  Your address still could not be verified.
                                </template>
                                <template v-slot:msg>
                                  <p>*Please check your address for accuracy. If correct, select it again or enter a new address.</p>
                                  <p>*You can continue with order, we will check and validate this address in the mean time.</p>
                                </template>
                              </ml-alert>

                          <Address @selectedAddress="selectedAddress">
                            <template v-slot:address="slotProps">
                              <div :class="{'tw-bg-gray-n0': order.address === slotProps.address}"
                                class="tw-text-black tw-py-2 tw-px-4 tw-relative tw-w-full tw-h-full tw-cursor-pointer"
                                @click="selectAddress(slotProps.address)">
                                <div v-if="slotProps.address.type_id" class="tw-flex">
                                  <div class="tw-rounded tw-items-center tw-justify-center"
                                    :class="getColor(slotProps.address.type.code)"
                                    >
                                    <span class="tw-text-brown tw-text-xs tw-font-xs tw-ml-1 tw-mr-1 tw-font-semibold">
                                      {{ slotProps.address.type.name }}
                                    </span>
                                  </div>
                                </div>
                                <div class="tw-text-xs tw-space-y-1 tw-flex tw-flex-col">
                                  <span>{{ slotProps.address.address1 }} {{ slotProps.address.address2 }}</span>
                                  <span v-if="slotProps.address.city && slotProps.address.state">{{ slotProps.address.city.name }}, {{ slotProps.address.state.code }}, {{ slotProps.address.postal_code }}</span>
                                </div>
                                <div class="tw-absolute tw-top-2 tw-right-2">
                                  <svg v-if="order.address === slotProps.address" width="16" height="16" viewBox="-1 -1 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z" fill="#E1E1E1"/>
                                    <circle cx="8" cy="8" r="6" fill="#2D2D2D"/>
                                    <path d="M8 15C4.13401 15 1 11.866 1 8H-1C-1 12.9706 3.02944 17 8 17V15ZM15 8C15 11.866 11.866 15 8 15V17C12.9706 17 17 12.9706 17 8H15ZM8 1C11.866 1 15 4.13401 15 8H17C17 3.02944 12.9706 -1 8 -1V1ZM8 -1C3.02944 -1 -1 3.02944 -1 8H1C1 4.13401 4.13401 1 8 1V-1Z" fill="#2D2D2D"/>
                                  </svg>
                                  <span v-else class="tw-h-4 tw-w-4 tw-inline-flex tw-border-2 tw-border-gray-n1 tw-rounded-full">
                                  </span>
                                </div>
                              </div>
                            </template>
                            <template v-slot:newAddress>
                              <div class="tw-cursor-pointer tw-col-span-1 tw-rounded-xl tw-border-gray-n0 tw-border tw-py-5" @click="newAddressForm = true">
                                <div class="tw-flex tw-items-center tw-justify-center">
                                  <span class="tw-font-semibold tw-text-sm">{{ $t('address.add_address') }}</span>
                                  <plus-icon class="tw-w-4 tw-w-4 tw-ml-2"/>
                                </div>
                              </div>
                            </template>

                          </Address>
                        </div>
                        <div v-else class="tw-mb-4 tw-mt-2">
                          <address-form :id="null" @submitted="storeAddress">
                            <template v-slot:cancelButton>
                              <button @click="newAddressForm = false" class="tw-mt-6 tw-px-8 tw-py-3 tw-border tw-border-solid tw-ml-3 tw-border-red tw-text-base tw-font-medium tw-rounded-xl tw-text-red focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-red">Cancel</button>
                            </template>
                          </address-form>
                        </div>
                      </div>
                      <!-- END -->

                      <!-- Terms -->
                      <!--
                      <div :class="{'tw-bg-gray-n0': order.terms === true}"
                          class="tw-rounded tw-text-black tw-py-2 tw-px-4 tw-relative tw-w-full tw-mt-2 tw-mb-2 tw-h-full tw-border-1 tw-border-gray-n1"
                          >
                          <router-link class="tw-text-red tw-inline-flex tw-items-center tw-mb-3 tw-mt-3 routerLink"
                            :to="{path: '/vendor/'+this.cart.vendor.id+'/terms-and-conditions' }"
                            target="_blank"
                            >
                              {{$t('vendor.toc')}}
                              <arrow-up-right-icon class="tw-w-4 tw-h-4 tw-ml-1" />
                              <span class="tw-ml-1 tw-text-black tw-font-semibold tw-text-sm routerLink">Click to review</span>
                          </router-link>
                          
                          <div class="tw-flex tw-items-center tw-justify-items-center">
                              <input id="vue-checkbox-list" type="checkbox" v-model="order.terms" checked  
                              class="tw-w-3 tw-h-3 tw-text-black tw-bg-gray-n3 tw-rounded tw-ml-2 tw-cursor-pointer">
                              <span for="vue-checkbox-list" class="tw-ml-1 tw-text-xs tw-font-xs tw-text-gray-n3">
                                {{!this.order.terms ? $t('vendor.toc_cart_label'):$t('vendor.toc_cart_confirm_label')}}
                              </span>
                          </div>

                      </div>
                      -->
                    </v-stepper-content>

                    <v-stepper-content step="3" class="tw-p-0">

                    <!-- Promotion -->
                      <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-4 tw-mb-2">
                      <p>Mango & Lola Current Promotions</p>
                      <Promotions @selectedPromotions="promotionsSelected" :showTerms="showPromoTerms">

                        <template v-slot:terms_link>
                          <div class="tw-cursor-pointer tw-col-span-1  tw-py-5" @click="showPromoTerms = !showPromoTerms">
                            <div class="tw-flex tw-items-center tw-justify-start">
                              <span class="tw-font-semibold tw-text-xs">{{showPromoTerms ? 'Hide Terms':$t('legal.terms')}}</span>
                            </div>
                          </div>
                        </template>

                      </Promotions>

                      </div>

                    </v-stepper-content>

                    <v-stepper-content align-center step="4" class="tw-p-0">
                      <span v-if="paymentProcessing" class="tw-flex tw-flex-col tw-items-center tw-mt-10">
                        <p class="tw-font-semibold tw-text-base">Your payment is being processed</p>
                        <p class="tw-text-xs tw-mb-10">This process will take around 1 minute</p>
                        <RedSpinner />
                      </span>
                      <span v-show="!paymentProcessing">
                        <div class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-4 tw-mb-1">
                          <h2 class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3 tw-text-base">{{$t('cart.card_info')}}</h2>
                          <v-form ref="form3" v-model="valid3" :lazy-validation="lazy">
                            <Checkout
                                v-if="user && order.address && e1==4"
                                :user="user"
                                :address="order.address"
                                :paymentProceed="paymentProceed"
                                :total="total_value_of_selected_with_tax"
                                :cartItems="order"
                                :cart="order"
                                :newOrder="true"
                                :selectedVendorPaymentOption="selectedPaymentOption"
                                @paymentProcessing="paymentStatus"
                                @validPaymentInputs="setValidPaymentInputs"
                                @ordered="ordered"
                                @orderFailed="orderFailed"
                                @goback="goBack"

                            />
                          </v-form>
                          <div class="tw-text-xs tw-text-black tw-italic tw-mt-5">
                            Placing this order, you authorize payment processing.
                          </div>
                        </div>

                          <!-- Payment option -->
                        <div v-if="selectedPaymentOption" class="tw-border tw-border-1 tw-rounded tw-border-gray-n1 tw-p-4 tw-mb-2 tw-mt-2">
                          <h2 class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-3 tw-text-base">{{$t('booking.payment_option')}}</h2>
                          <h4 class="tw-font-semibold tw-text-black tw-leading-relaxed tw-text-sm tw-mb-2">
                            {{ selectedPaymentOption.name}}
                          </h4>
                          <p class="tw-m-0 tw-text-xs">{{ selectedPaymentOption.description }}</p>
                        </div>
                      </span>

                    </v-stepper-content>

                  </v-stepper-items>

                  <!-- Order subtotal -->
                  <div v-if="!paymentProcessing" class="tw-border tw-border-gray-n1 tw-rounded-lg tw-p-4 tw-mb-1">
                    <div class="tw-text-xs">
                      <h2 class="tw-font-semibold tw-text-black tw-text-sm tw-leading-relaxed tw-mb-3 tw-text-base">Order info</h2>
                      <div class="tw-space-y-3 tw-mb-3 tw-pb-3 tw-border-b tw-border-gray-n0">
                        <div class="tw-flex tw-items-center tw-justify-between">
                          <span class="tw-text-gray-n3">Items subtotal</span>
                          <span>{{ cart.total_price_without_tax | currency }}</span>
                        </div>
                        <div class="tw-flex tw-items-center tw-justify-between">
                          <span class="tw-text-gray-n3">Tax amount</span>
                          <span>{{ cart.total_tax | currency }}</span>
                        </div>
                        <v-progress-linear
                            v-show="loadingTax"
                            indeterminate
                            color="yellow"
                            height="1"
                            class="tw-mb-1 tw-mt-1"
                        ></v-progress-linear>
                      </div>
                      <div class="tw-flex tw-text-black tw-items-center tw-justify-between">
                        <span class="tw-text-xs">Total</span>
                        <span class="tw-text-base tw-leading-relaxed tw-font-semibold">{{ cart.total_price | currency }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Msg if emailExist -->

                    <ml-alert v-if="emailExist" class="tw-mb-2 tw-mt-1">
                      <template v-slot:title>
                        It seems that this email has already been taken. You can Sign-In or Continue as a Guest.
                      </template>
                    </ml-alert>

                  <!-- Button -->
                  <div class="tw-mt-auto tw-grid tw-gap-x-4" :class="[e1 !== 1 ? 'tw-grid-cols-2' : 'tw-grid-cols-1']">
                    <button v-if="e1 != 1" @click="e1 = e1-1"
                           class="tw-border tw-border-solid tw-border-red tw-block tw-rounded-xl tw-text-red tw-text-center tw-py-4 tw-text-sm tw-font-semibold tw-leading-relaxed tw-w-full"
                           :disabled="paymentProcessing"
                    >
                      {{ $t('general.previous') }}
                    </button>
                    <button
                        class="tw-block tw-rounded-xl tw-text-white tw-text-center tw-py-4 tw-text-sm tw-font-semibold tw-leading-relaxed tw-w-full"
                        :class="loadingTax ? 'tw-bg-red-100':'tw-bg-red'"
                        v-if="e1 === 1"
                        @click="next(e1)"
                        :disabled="paymentProcessing || loadingTax || loading"

                    >
                      {{ $t('general.next') }}
                    </button>
                    <button
                        class=" tw-block tw-rounded-xl tw-text-white tw-text-center tw-py-4 tw-text-sm tw-font-semibold tw-leading-relaxed tw-w-full"
                        :class="loadingTax ? 'tw-bg-red-100':'tw-bg-red'"
                        v-if="e1 === 2"
                        @click="next(e1)"
                        :disabled="paymentProcessing  || loadingTax || loading"

                    >
                      <span v-if="!emailExist">{{ $t('general.next') }}</span><span v-else>{{$t('auth.continue_as_guest')}}</span>
                    </button>
                    <button
                        class="tw-block tw-rounded-xl tw-text-white tw-text-center tw-py-4 tw-text-sm tw-font-semibold tw-leading-relaxed tw-w-full"
                        :class="loadingTax ? 'tw-bg-red-100':'tw-bg-red'"
                        v-if="e1 === 3"
                        @click="next(e1)"
                        :disabled="paymentProcessing  || loadingTax || loading"

                    >
                      {{ $t('cart.check_out') }}
                    </button>
                    <button v-if="e1 === 4 && !orderMessageDialog"
                           class="tw-block tw-rounded-xl tw-text-center tw-py-4 tw-text-sm tw-font-semibold tw-leading-relaxed tw-w-full"
                            :class="paymentProcessing ? 'tw-bg-red-100 tw-text-black':'tw-bg-red tw-text-white'"
                            @click="next(e1)"
                           :disabled="!valid1 || !valid2 || paymentProcessing || !validPaymentInputs"

                    >
                    <span v-if="paymentProcessing">
                      {{$t('cart.processing')}}
                    </span>
                    <span v-else>
                    {{$t('cart.place_order')}} | {{this.cart.total_price | currency}}
                    </span>
                    </button>

                  </div>
                </v-stepper>

                <!-- Payment/Order Mesage-->
                <v-dialog
                    v-if="orderResponse"
                    v-model="orderMessageDialog"
                    hide-overlay
                    persistent
                    width="400"
                >
                  <v-card
                      color="light-green lighten-1"
                      dark
                  >
                    <v-card-title>
                     {{ paymentIntentResponse.status }}
                    </v-card-title>
                    <v-card-text>
                      {{ orderResponse.message }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn small outlined text color="light-blue darken-1" @click="viewOrder()">
                        {{ $t('cart.view_order') }}
                      </v-btn>
                      <v-spacer/>
                      <v-btn small text @click="resetCart()">
                        {{ $t('cart.close') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
          </div>
        </div>
      </div>

    </v-dialog>

  </div>

</template>


<script>

import { mapGetters } from 'vuex'
import Address from '@/components/Address.vue'

import Checkout from '../components/Checkout.vue'
import { ShoppingCartIcon, Trash2Icon, ImageIcon, PlusIcon, EyeIcon } from "vue-feather-icons";
import Gallery from "@/components/Gallery";
import AddressForm from "@/components/AddressForm";
import Promotions from "@/components/Promotions";
import MlAlert from "@/components/layout/MlAlert";
import MlLabel from "@/components/layout/form/MlLabel";
import MlInputText from "@/components/layout/form/MlInputText";
import MlErrorMsg from "@/components/layout/form/MlErrorMsg";
import i18n from '@/i18n';
import RedSpinner from "@/components/RedSpinner"
//import store from '../store';

/*
function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
*/
export default {
  middleware: 'auth',
  components: {
    AddressForm,
    Address,
    Checkout,
    ShoppingCartIcon,
    Trash2Icon,
    Gallery,
    ImageIcon,
    PlusIcon,
    //ArrowUpRightIcon,
    MlAlert,
    MlLabel,MlInputText,MlErrorMsg,EyeIcon,
    Promotions,
    RedSpinner
  },
  data() {
    return {
      newAddressForm: false,
      loadingProgressLinear: false,
      step: {
        s2: {
          panel: 0,
        }
      },
      showPasswordValue: false,
      order: {
        items: null,
        address: {
          valid:false
        },
        terms: true,
        person:{
          firstname: null,
          lastname: null,
          email: null,
          email_confirmation: null,
          guest: false,
          terms_and_conditions: false,
          create_account: false,
          password: null,
          password_confirmation: null,
          skip_email: false
        },
        promotions: null
        //vendor_id: null,
        //payment: {}
      },
      e1: 1,
      items2: [],
      viewMyCart: false,
      valid4: false,
      valid3: false,
      valid1: false,
      valid2: false,
      lazy: true,
      loading: false,
      loadingTax: false,
      orderError: [],
      paymentProcessing: false,
      orderMessageDialog: false,
      validPaymentInputs: false,
      paymentProceed: false,
      selectedPaymentOption: null,
      rules: {
        required: value => !!value || 'Required.',
        max: v => v <= 10 || 'Max 10 peices',
        range: v => (v >= 0 && v <= 10) || 'Range 0-10 peices',
        ok: true
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

      orderResponse: null,
      paymentIntentResponse: null,
      currentUpdateItem: null,
      errors: [],
      promotions:[],
      showPromoTerms: false,
    }
  },
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      cartWindow: 'cart/getViewMyCart',
      user: 'auth/user',
      check: 'auth/check',
      guestGhost: 'auth/guestGhost'
    }),
    emailExist: function () {

      if (this.errors.find(item => item.email && item.email == true)) {
        return true;
      }else{
        return false;
      }

    },
    tax_validate: function () {

      if (this.cart.items.length > 0) {
        if (this.cart.items.filter(item => item.is_item_taxable && item.tax_synced == false).length) {
          return false;
        }
      }
      return true;

    },    
    paymentoptions: function () {

      if (this.cart) {
        return this.cart.vendor.paymentmethods;
      } else {
        return null;
      }


    },
    selected: function () {

      if (this.cart.items.length > 0) {
        if (this.cart.items.filter(item => item.checked).length) {
          return true;
        }
      }

      return false;

    },
    total_value_of_selected_with_tax: function () {
      return this.total_value_of_selected + this.total_tax_amount_of_selected;
    },
    total_value_of_selected: function () {

      if (this.cart.items.length > 0) {
        return this.cart.total_price;
      } else {
        return 0;
      }
      /*
          if(this.cart.items.length > 0){
              var summed = 0;
              var val_package = this.cart.items.filter(item => item.checked).map(a => a.total_price);
              for (var key in val_package) {
                  summed += val_package[key];
              }
              return summed;

          // summed = summed.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          // console.log(summed);
              //return summed;

          }

          return 0;
      */
    },
    total_tax_amount_of_selected: function () {

      if (this.cart.items.length > 0) {
        return this.cart.total_tax;
      } else {
        return 0;
      }


      /*
      if(this.cart.items.length > 0){
          var val = this.cart.items.filter(item => item.checked).map(a => a.tax_amount);
          var summed = 0;
          for (var key in val) {
              summed += val[key];
          }
          return summed;

          //summed = summed.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
         // console.log(summed);
         // return summed;

      }

      return 0;
      */
    },
    total_number_of_selected: function () {
      if (this.cart.items.length > 0) {
        return this.cart.items.filter(item => item.checked).filter(item => item.quantity).length;
      } else {
        return 0;
      }

    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 220
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 600
        case 'xl':
          return 800
        default:
          return 0
      }
    },
  },
  mounted() {
    this.getItem();
    //this.$store.dispatch('cart/fetchCartItems')
  },
  watch: {
    'selectedPaymentOption': function () {
      this.order.vendorPaymentOption = this.selectedPaymentOption;
    },
    async cartWindow() {
      await this.getItem();
      this.viewMyCart = this.cartWindow;
    },
    cart() {
      if (this.cart && this.cart.items.length === 0) {
        this.viewMyCart = false;
      }
      this.order.items = this.cart ? this.cart.items : [];
    }
  },
  methods: {
    togglePassword() {
      this.showPasswordValue = !this.showPasswordValue;
    },
    hidePassword() {
      this.showPasswordValue = false;
    },
    getColor(code){
      if(code == 'ha'){
        return 'tw-bg-antiquewhite';
      }else if (code == 'sa'){
        return 'tw-bg-lightred';
      }else if (code == 'wva'){
        return 'tw-bg-green-100';
      }else{
        return 'tw-bg-gray-n1';
      }
    },    
    cartOpen() {
      this.viewMyCart = true;
    },
    closeCart() {
      let status = false;
      this.$store.dispatch('cart/setCartWindow', {status});
      this.viewMyCart = false;
    },
    async nextGuest(){
        this.order.person.skip_email = true;
        await this.next(this.e1);
    },
    async next(value) {

      let vl = false;
      this.order.person.skip_email = this.emailExist;

      if (value == 1) {
        vl = await this.validateStep('step1');
      } else if (value == 2) {
        vl = await this.validateStep('step2');
      } else if (value == 3) {
        vl = await this.validateStep('step3');
      } else if (value == 4) {
        vl = await this.validateStep('step4');
      }

      if(vl === true) {
        // Skip promotion step if promotion does not exist
        if(this.promotions.length == 0 && value == 2){
          await this.next(value+1);
        }

        if (value == 4) {
          this.onComplete();
        } else {
          this.e1 = this.e1 + 1;
        }

      } else {
        console.log("Validation fail");
      }

    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async validateStep(name) {
      //return true;
      this.errors = [];
      let label = '';
      var isValid = false;
      if (name == 'step1') {
        //return true;


        if (!this.cart.items.filter(item => item.checked).length) {
          this.valid1 = false;
          return false;
        }

        if (this.cart.items.filter(item => item.quantity).length == 0) {
          //console.log(this.cart.items.filter(item => item.quantity).length);
          this.valid1 = false;
          return false;
        }

        console.log("validation 1: " + true);
        this.valid1 = true;
        return true


      }
      if (name == 'step2') {
        //return true;
        this.valid2 = false;
        console.log("Validating step 2...");
        //console.log("call update start",this.valid2);
        if (!this.order.address) {
          label = "Address Required";
          this.errors["address"] = label;
          this.errors.push(label);
        }

        // If guestGhost
        if(this.guestGhost){

          if (!this.order.person.firstname) {
            label = "First Name Required";
            this.errors["firstname"] = label;
            this.errors.push(label);
          }
          if (!this.order.person.lastname) {
            label = "Last Name Required";
            this.errors["lastname"] = label;
            this.errors.push(label);
          }
          if (!this.order.person.email) {
            label = "Email Required";
            this.errors.push(label);
            this.errors["email"] = label;

          } else if (!this.validEmail(this.order.person.email)) {
            label = "Valid Email Required";
            this.errors.push(label);
            this.errors["email"] = label;

          } else if (this.order.person.email !== this.order.person.email_confirmation) {
            label = i18n.t('auth.email_mismatch');
            this.errors["email"] = label;
            this.errors["email_confirmation"] = label;
            this.errors.push(label);

          }
          if (!this.order.person.terms_and_conditions) {
            label = "Terms and Conditions Required";
            this.errors["terms_and_conditions"] = label;
            this.errors.push(label);
          }

          // If guest and wants to create account
          if(this.order.person.create_account){

            if (!this.order.person.password) {
              label = i18n.t('auth.required_password');
              this.errors["password"] = label;
              this.errors.push(label);
            }else{
              if(this.order.person.password.length < 8){
                label = i18n.t('auth.password_min');
                this.errors["password"] = label;
                this.errors.push(label);
              }
              if (this.order.person.password !== this.order.person.password_confirmation) {
                label = i18n.t('auth.password_mismatch');
                this.errors["password_confirmation"] = label;
                this.errors.push(label);
              }
            }
          }

        }

        if (!this.errors.length && this.user.guest) {
            await this.updateGuest();
        }

        if (!this.errors.length) {
          //console.log("validation 2: " + true);
          this.valid2 = true;
          return true;
        }else{
          console.log("Validating step 2 false");
          this.valid2 = false;
          return false;
        }


      }
      if (name == 'step3') {

        console.log("validation 3: " + true);
        this.valid3 = true;
        return true;

      }
      if (name == 'step4') {
        console.log("validation 4: " + true);
        this.valid3 = true;
        return true;
        // TODO remove this as there is no more form 2
        /* isValid = this.$refs.form2.validate();
         console.log("validation 2: " + isValid);
         return isValid*/
      } else {
        return isValid;
      }
    },
    onComplete() {
      //this.paymentProcessing = true;

      //console.log("placeOrder");
      this.placeOrder();
    },
    promotionsSelected(data){
      this.promotions = data;
      this.order.promotions = data;
    },
    viewOrder() {
      this.$router.push({name: 'order.show', params: {id: this.orderResponse.order.id}});
      this.resetCart();
    },
    async resetCart() {
      this.paymentProcessing = false;
      this.orderMessageDialog = false;
      this.orderResponse = null;
      this.paymentIntentResponse = null;
      let status = true;
      this.$store.dispatch('cart/setOrderStatus', {status});
      await this.getItem();
      setTimeout(function () {
        this.e1 = 1;
      }.bind(this), 1000);

      //this.orderStatus = null;

    },
    goBack() {
      this.resetCart();
    },
    async placeOrder() {
      //let uri = '/api/orders';
      let items = this.order.items.filter(item => item.checked).filter(item => item.quantity);
      this.order.items = items;
      this.paymentProceed = true; // Activate payment to stripe
    },
    async getItem() {
      this.loading = true;
      await this.$store.dispatch('cart/fetchCart');
      if(this.cart) this.selectedPaymentOption = this.cart.vendor.paymentmethods[0];
      this.loading = false;
      /*
      this.$http.get('api/cart-items')
          .then(({data})  => {
              this.items2 = data.data
          })
          .catch(error => {
              console.log('Error loading orders data: ' + error)
          }).finally(() =>
          this.loading = false,
          )
      */
    },
    async updateGuest(){

      let _self = this;
      //console.log("updateguest start");
      await this.$http.post('/api/guest-update', this.order.person)
          .then(() => {
            _self.$store.dispatch('auth/fetchUser');
             return true;
          })
          .catch((error) => {
            ///console.log(error);
            this.errors.push(error.data.errors);
            if(!this.emailExist) this.$toast.error(error.data.message, "Error");
            return false;
          });
    },
    async updateItem(item) {

      //console.log(item);
      this.loading = true;
      this.currentUpdateItem = item.id;

      await this.$store.dispatch('cart/updateCartItem', {
        item
      }).then(() => {
        //this.$toast.success('Success', "Success");
      });

      await this.getItem();
      this.loading = false;
      this.currentUpdateItem = null;


      /*
      let uri = '/api/user/'+this.item.id;
      this.$http.patch(uri, this.item)
      .then((response) => {
          this.$toast.success(response.data.message, "Success"),
          this.$store.dispatch('auth/fetchUser')
      });
      */
    },
    async selectedAddress(data) {
      this.order.address = data.item;
      let index = this.errors.indexOf("Address Required");
      if (index > -1) {
        this.errors.splice(index, 1);
      }
      this.valid2 = false;
      await this.reCheckTax(data.item);
      this.valid2 = true;
      if (!this.order.address) {
        this.valid2 = false;
      }
    },
    async selectAddress(address) {
      this.order.address = address;
      this.valid2 = false;
      await this.reCheckTax(address);
      this.valid2 = true;
      if (!this.order.address) {
        this.valid2 = false;
      }
    },
    async reCheckTax(address){
      if(typeof address !== "undefined"){
        //console.log("Default selected address valid: ", address.valid);
        if(address.valid){
          // call ep and recalculate tax
          await this.recalculateTax(this.cart, address);
        }else{
          this.$toast.info("We still validating this address...", "Info",{
            timeout: 5000,
            titleColor: "black",
            position: 'topLeft'
          });
        }

      }else{
        this.$toast.info('Please enter your address in the following step which is required to calculate sales tax.', 'Hey',{
          timeout: 5000,
          color: 'gray',
          titleColor: "black",
          position: 'topLeft',
          theme: 'light'
        });
      }

    },
    async recalculateTax(cart,address) {
      this.loading = true;
      this.loadingTax = true;
      this.loadingProgressLinear = true;
      let url = '/api/cart/'+cart.id+'/address/'+address.id+'/update-tax';
      await this.$http.post(url)
          .then(({data}) => {
            this.getItem();
            this.$toast.success(data.message, "Success",{
              timeout: 3000,
              position: 'topLeft'
            });
            this.loadingTax = false;
            this.loadingProgressLinear = false;
          })
          .catch((error) => {
            console.log('error', error.data.error);
            this.$toast.error(error.data.error, "Error",{
              timeout: 3000,
              position: 'topLeft'
            });
            this.loadingTax = false;
            this.loadingProgressLinear = false;
          });
      this.loadingTax = false;
      this.loadingProgressLinear = false;
    },    
    async storeAddress(data) {
      this.loading = true;
      let item = data.item;
      await this.$http.post('/api/address', item)
          .then(({data}) => {
            this.$toast.success(data.message, "Success",{
              timeout: 1000,
              position: 'topLeft'
            });
            this.newAddressForm = false;
          })
          .catch((error) => {
            console.log('error', error.data.error);
            this.$toast.error(error.data.error, "Error",{
              timeout: 1000,
              position: 'topLeft'
            });
          });
      this.loading = false;
    },
    paymentStatus(data) {
      this.paymentProcessing = data.paymentProcessing;
      if (!this.paymentProcessing) {
        this.paymentProceed = false;
      }
      this.orderError = data.orderError;
    },
    orderFailed(error){
      this.orderMessageDialog = false;
      this.paymentProcessing = false;
      //console.log("orderFailed",error);
      this.$toast.info(error.data.message, "Message",{
        timeout: 3000,
        position: 'topLeft'
      });
    },
    async ordered(data) {
      //console.log(data.response);
      //this.valid1= false;
      //this.valid2= false;
      //this.valid3= false;
      //this.$toast.success(data.response.paymentIntent.status, "Success");
      this.orderResponse = data.response.orderResponse;
      this.paymentIntentResponse = data.response.paymentIntent;
      this.orderMessageDialog = true;
      this.paymentProcessing = false;
      this.$toast.success(data.response.orderResponse.message, "Success");
      this.$emit('ordered', data.response.paymentIntent.status);
      //await this.resetCart();
      //console.log("order.show");

    },
    setValidPaymentInputs(data) {
      this.validPaymentInputs = data.validPaymentInputs;
    },
    destroy(vId) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.removeItem(vId);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    removeItem(id) {
     // this.loading = true;
      let uri = '/api/cart-items/' + id;
      this.$http.delete(uri)
          .then((response) => {
            this.getItem();
            this.$toast.success(response.data.message, "Success", {timeout: 1000});
          });
     // this.loading = false;

    },
    destroySelected(value) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.removeSelected(value);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    removeSelected(value) {

      let data = value ? this.items.filter(item => item.checked) : this.items;
      //console.log(data);

      let uri = '/api/cart-items/removeSelected';
      this.$http.post(uri, {items: data})
          .then((response) => {
            this.getItem();
            this.$toast.success(response.data.message, "Success", {timeout: 1000});
          }).catch(error => {
        //console.log(error);
        this.$toast.warning(error.data.message, "Warning")
      });

    },
  }
}
</script>

<style scoped>
.flex {
  display: flex;
  flex-wrap: wrap;
}
.flex .lg {
  flex-basis: 0;
  flex-grow: 3;
  margin: 3px;
}
.flex .md {
  flex-basis: 0;
  margin: 3px;
  flex-grow: 2;
}
.basic {
  max-width: 700px;
  min-width: 270px;
}
.empty-height {
  height: 500px;
}
</style>





