<template>
  <router-link :to="{ name: 'welcome' }">
    <span v-if="1==2" class="routerLink">{{ this.$appName }}</span>
    <v-img src="@/assets/logo-web-01.svg" >
    </v-img>
  </router-link>
</template>

<script>

export default {
  name: 'Logo',
}
</script>
