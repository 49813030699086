<template>
<div v-bind="$attrs" v-on="$listeners">
      <div class="tw-rounded-xl tw-bg-lightred tw-flex">
        <div class="tw-flex tw-h-16 tw-w-16 tw-items-center tw-justify-center tw-flex-shrink-0">
            <span><alert-triangle-icon class="tw-text-red tw-h-6 tw-w-6"></alert-triangle-icon></span>
        </div>
        <div class="tw-flex-col tw-flex  tw-max-w-full tw-py-1 tw-pr-1 tw-justify-center tw-space-y-0.5">
          <span class="tw-text-white tw-font-semibold tw-text-sm"><slot name="title"/>{{title}}</span>
          <span class="tw-text-white tw-text-sm"><slot name="msg" />{{msg}}</span>
        </div>
      </div>
</div>
</template>

<script>
import { AlertTriangleIcon  } from "vue-feather-icons";
export default {
  props: {
    title: {
      default: '',
      type: String
    },
    msg: {
      default: '',
      type: String
    },
  },
  components: {AlertTriangleIcon},
}

</script>
