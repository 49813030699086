<template>
  <div>
    <v-autocomplete
        v-model="selectedData"
        :items="items"
        item-value="id"
        item-text="name"
        :search-input.sync="searchData"
        :hide-no-data="!searchData"
        :loading="loadingData"
        :placeholder="$t('search.start')"
        class="feed-search-fix"
        clearable
        @click:clear="clearSearch"
        no-filter
        append-icon=""
        rounded
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            {{ $t('search.search_for') }}
            <strong>{{ $t('search.vendor') }}</strong>
          </v-list-item-title>
        </v-list-item>
      </template>

<!--      <template v-slot:append>
        <div class="tw-bg-black tw-text-white tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-xl">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.5 17.5L13.875 13.875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </template>-->

      <template
          v-slot:item="data"
      >
        <div class="row mb-1 center text-caption" @click="viewVendor(data.item.vendor.id)">

          <v-avatar size="36px" class="mr-2">
            <v-img :src="data.item.vendor.avatar"></v-img>
          </v-avatar>

          <strong>{{ data.item.vendor.name }}</strong>

          <div class="ml-2 mr-2">
            <strong>&bull;</strong>
          </div>

          {{ data.item.name.toUpperCase() }}

          <div class="ml-2 mr-2">
            <strong>&bull;</strong>
          </div>

          <span v-html="data.item.description.substring(0, 100)"></span>


        </div>

        <!--
        <v-card
          @click="viewVendor(data.item.vendor.id)"
          elevation="1"
          class="mx-auto mb-1 rounded-lg"
          color="grey lighten-4"
          width="100%"
          >


          <v-card-title>
           <v-spacer/>
            {{data.item.name.toUpperCase()}}
            <v-spacer/>
            <p>{{data.item.vendor.name}}</p>
          </v-card-title>
          <v-card-text>
            {{ data.item.description | truncate(100)}}
          </v-card-text>
        </v-card>
        -->


      </template>


    </v-autocomplete>

  </div>
</template>

<script>

//import axios from 'axios'
//import $ from 'jquery'
//import { debounce } from 'vue-debounce'

export default {
  data() {
    return {
      searchData: null,
      loadingData: false,
      selectedData: null,
      items: [],
      timer: null
    };
  },
  watch: {
    searchData(val) {

      if (this.searchData == null) return;

      if (this.searchData.length < 3) return;

      // Items have already been loaded
      //if (this.items.length > 0) return

      // Items have already been requested
      if (this.loadingData) return;

      if (this.typing) return

      // Debounce the input and wait for a pause of at
      // least 200 milliseconds. This can be changed to
      // suit your needs.
      //debounce(this.fetchResults, 500)(val, this)
      /*
      setTimeout(function () {
        this.fetchResults(val)
      }.bind(this), 400);
      */

      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
          this.fetchResults(val)
      }, 600);


    }
  },
  methods: {
    viewVendor(val) {

      if (val !== null) {
        let routeData = this.$router.resolve({name: 'showcasework.show', params: {id: val}});
        window.open(routeData.href, '_blank');
        //this.$router.push({ name: 'showcasework.show', params: { id: val.id } })
      }

    },
    customFilter() {
      return true;
    },
    clearSearch() {
      this.searchData = null;
      this.items = [];
    },
    fetchResults(query) {

      this.loadingData = true;

      const params = {
        "query": query
      };

      this.$http.get('api/search', {params})
          .then(({data}) => {
            this.items = data.data;

          })
          .catch(error => {
            console.log('Error loading data: ' + error)
            this.errored = true
          })
          .finally(() => this.loadingData = false)

    },

  }
}
</script>

