<template>
  <v-menu scrollable max-width="350px"
          offset-y
          :close-on-content-click="false"
  >
    <template v-slot:activator="{ attrs, on }">
      <div
          class="tw-relative tw-ml-4"
          v-bind="attrs"
          v-on="on"
      >
        <div>
          <div v-if="number > 0" class="tw-bg-red tw-w-3.5 tw-h-3.5 tw-rounded-full tw-flex tw-items-start tw-justify-center tw-absolute tw--right-0.5 tw-top-0 tw-text-white tw-font-semibold" style="font-size: 8px">
            {{ number }}
          </div>
          <bell-icon class="tw-w-5 tw-text-brown" stroke-width="1" />
        </div>
      </div>

    </template>

    <v-flex
        class="black--text white flex darken-3 overflow-auto"
    >

      <v-card v-if="number > 0" max-height="400" scrollable class="ma-1" elevation="0" :key="this.messages.length">
        <v-toolbar
            color="grey lighten-1"
            dark
            elevation="0"
        >
          <v-badge v-if="number > 0"
                   color="red lighten-1"
                   :content="number"
                   bordered
                   overlap
          >
            <v-icon left>mdi-bell-outline</v-icon>
          </v-badge>

          <v-spacer/>

          <!-- Settings -->
          <v-menu v-model="settings">
            <template v-slot:activator="{ attrs, on }">
              <v-icon v-bind="attrs"
                      v-on="on" @click="settings = true">mdi-dots-horizontal
              </v-icon>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-btn small text @click="msgClearAll()">
                    {{ $t('notification.clear_all') }}
                    <v-icon right color="red lighten-1" small>mdi-delete-variant</v-icon>
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>

        <v-list shaped>
          <v-list-item-group
              v-model="settingsItem"

          >
            <template v-for="(item,index) in this.messages">
              <v-list-item :key="item.id">
                <!--
                <v-list-item-avatar>
                    <v-btn icon v-if="item.data.mtype=='message'"
                    :to="{ name: 'messages' }"
                    >
                    <v-icon>mdi-email-outline</v-icon>
                    </v-btn>
                    <v-icon v-else-if="item.data.mtype=='order'">mdi-shopping-outline</v-icon>
                    <v-icon v-else-if="item.data.mtype=='quote'">mdi-shopping-search</v-icon>
                    <v-icon v-else-if="item.data.mtype=='payment'">mdi-currency-usd</v-icon>
                    <v-icon v-else>mdi-bell-outline</v-icon>

                </v-list-item-avatar>
                -->
                <v-list-item-content>
                  <!--
                  <router-link :to="{ name: getRoute(item.data.mtype) }" class="routerLink">
                      <v-list-item-title  class="text-xs">
                      {{item.data.from | truncate(30)}}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-xs">
                      {{item.data.message | truncate(30)}}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                      class="text-xs"

                      >
                       {{item.created_at}}
                      </v-list-item-subtitle>

                  </router-link>
                  -->
                  <v-list-item-title class="text-xs tw-cursor-pointer" @click="goTo(item)">
                    {{ item.data.title | truncate(30) }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                      class="text-xs"

                  >
                    {{ item.created_at }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>

                  <v-icon small @click="showEvent($event,item)">mdi-dots-horizontal</v-icon>
                </v-list-item-action>

              </v-list-item>
              <v-divider
                  v-if="index + 1 < messages.length"
                  :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-actions>
          <v-btn v-if="pegination < meta.last_page"
                 x-small text @click="pegination = pegination+1"
                 :loading="loading"

                 top
                 right
          >{{ $t('notification.load_more') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>

    <!-- Settings item -->
    <v-menu
        v-if="selectedElement"
        v-model="selected"
        offset-x
        :activator="selectedElement"
    >
      <v-list rounded>
        <v-list-item>
          <v-list-item-content>
            <v-btn x-small text @click="msgClear(selectedEvent)">
              <v-icon color="red lighten-1" left x-small>mdi-delete-variant</v-icon>
              <span class="text-xs">{{ $t('general.clear') }}</span>
            </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
//import Pusher from 'pusher-js';
//Pusher.logToConsole = true;
import { onMessage, isSupported } from "firebase/messaging";
import { BellIcon } from "vue-feather-icons";


export default {
  components: {
    BellIcon,
  },
  data() {
    return {
      //messages: [],
      number: 0,
      settings: false,
      settingsItem: [],
      pegination: 1,
      //meta: [],
      selected: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      loading: false,
    }
  },
  computed: {

    ...mapGetters({
      user: 'auth/user',
      messages: 'notification/getNotifications',
      meta: 'notification/getMeta',
    }),

    /*
    dbMessages(){
        return this.user.notifications.map(t => ({
        message: t.data.message,
        id: t.data.id,
        read_at: t.read_at,
        created_at: t.created_at

        }));
    }
    */

  },
  async mounted() {
    //await this.hookPusher();
    //await this.getItem();
    await this.fetchNotifications();

    const isIOSSafari = !!window.navigator.userAgent.match(/Version\/[\d\\.]+.*Safari/);
    const isIosChrome = navigator.userAgent.indexOf('CriOS') >= 0;
    const fcmSupported = await isSupported();
    if ('serviceWorker' in navigator && fcmSupported && !isIOSSafari && !isIosChrome) {
      //console.log("hookFcm");
      await this.hookFcm();
    }
  },
  watch: {
    meta: function () {
      this.number = this.meta.total;
    },
    "pegination": function () {
      //this.getItem();
      this.switchPage();
    },
  },
  methods: {
    goTo(item){
      console.log(item.data.mtype);
      let routeName = this.getRoute(item.data.mtype);
      console.log(routeName);
      if(this.$route.name != routeName) this.$router.push({ name: routeName });
    },
    switchPage() {
      const params = {
        "page": this.pegination ? this.pegination : this.$route.query.page
      };
      this.$store.dispatch('notification/fetchNotifications', params)
    },
    getRoute(type) {
      if (type == 'message') {
        return 'messages';
      } else if (type == 'order') {
        if (this.user.vendor == 1) {
          return 'vendor.bookings';
        } else {
          return 'orders';
        }
      } else if (type == 'quote') {
        if (this.user.vendor == 1) {
          return 'vendor.quotes';
        } else {
          return 'user.quotes';
        }
      } else if (type == 'payment') {
        if (this.user.vendor == 1) {
          return 'vendor.bookings';
        } else {
          return 'orders';
        }
      } else {
        return 'feed';
      }
    },
    // Pusher
    getItem() {
      const params = {
        "page": this.pegination ? this.pegination : this.$route.query.page,
      };

      let uri = '/api/notifications';
      this.$http.get(uri, {params}).then((response) => {
        this.messages = this.messages.concat(response.data.data),
            this.meta = response.data.meta
        //console.log(response);
      });
    },
    showEvent(event, item) {

      //console.log(event);

      const open = () => {

        this.selectedEvent = item;
        this.selectedElement = event.target;
        setTimeout(() => {
          this.selected = true
        }, 10)
      }

      if (this.selected) {
        this.selected = false
        setTimeout(open, 10)
      } else {
        open()
      }

    },
    msgClearAll() {
      this.messages = [];
      this.number = 0;
      // call Api
      let uri = '/api/notifications/markAllAsRead';
      this.$http.get(uri);

    },
    msgClear(item) {
      this.number = this.number - 1;
      var index = this.messages.indexOf(item);
      console.log(index);
      this.messages.splice(index, 1);

      let uri = '/api/notifications/' + item.id;
      this.$http.patch(uri);

    },
    hookPusher() {


      //this.messages = this.dbMessages;

      this.$pusher.connection.bind('state_change', function (states) {
        console.log('state.current=%s', states.current);
      });


      var name = 'private-App.User.'.concat(this.user.id)
      //console.log(name);
      var channel = this.$pusher.subscribe(name);
      channel.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', (data) => {

        let msg = {
          id: data.id,
          data: data
        }
        this.messages.unshift(msg);
        this.number++;
      });

      this.number = this.user.countUnreadNotifications;
      /*
      this.messages.forEach(element => {
          if(element.read_at === null){
              this.number++;
          }
      });
      */

    },
    async fetchNotifications() {
      await this.$store.dispatch('notification/fetchNotifications');
    },
    hookFcm() {


      const messaging = this.$messaging;
      //console.log("hookFcm");
      //console.log(messaging);


      onMessage(messaging, (payload) => {
        //console.log('Message received. ', payload);
        // this.messages = this.messages.concat(payload.data);

        let msg = {
          data: payload.data
        }
        //this.$store.dispatch('notification/setNotification', msg );
        this.messages.unshift(msg);
        this.number++;


        // ...
      });


    },
  }
}
</script>
<style scoped>
.scroll {
  overflow-y: scroll
}
</style>
