<template>
  <div>

    <v-dialog
        v-model="openModal"
        max-width="976"
        @click:outside="close"
    >
      <div class="sm:tw-grid sm:tw-grid-cols-2 tw-max-w-9xl tw-mx-auto tw-px-0 tw-relative ">
        <div class="tw-hidden sm:tw-grid">
          <img src="@/assets/img/budget_teaser.jpg" class="tw-object-cover tw-h-full" alt="Budget tool teaser">
        </div>

        <div class="tw-bg-red-100 tw-px-5 tw-py-7">
          <div class="tw-flex tw-justify-end"><XIcon class="tw-cursor-pointer tw-text-gray-n3" @click="close"/></div>
          <div class="tw-text-black">
            <h2 class="tw-text-2rem tw-font-serif tw-mb-8">Join the waiting list</h2>
            <p class="tw-text-sm tw-leading-relaxed">Sign up to receive an email when the tool is ready to use!</p>
          </div>
          <div id="hubspotForm" v-once></div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<style>
.hs-form-iframe {
  min-height: 100px;
}
</style>

<script>
import { XIcon } from 'vue-feather-icons'


export default {
  name: 'budgetToolTeaser',
  props: {
    openModal: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      loaded: false,
    }
  },
  components:{
    XIcon,
  },
  mounted() {
    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      this.loaded = true;
    })
  },
  updated() {
    if (this.loaded && window.hbspt) {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "22557972",
        formId: "003f6fac-eac8-4e83-846d-9e256c1d7c91",
        target: "#hubspotForm"
      });
    }
  },
  methods: {
    close(){
      this.$emit('closeModal');
    },
  }
};
</script>


