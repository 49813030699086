<template>
  <div class="tw-flex tw-flex-col tw-flex-grow">
    <navbar />
    <slot />
    <landing-footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import LandingFooter from "@/components/landing/LandingFooter";

export default {
  name: 'MainLayout',

  components: {
    LandingFooter,
    Navbar
  }
}
</script>
