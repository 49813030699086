<template>
  <div>
    <v-dialog
        v-model="profileCompleteness"
        max-width="600"
    >
      <div>
        <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">
          <h3 class="tw-font-semibold tw-mb-2">Complete these steps to Book New Couples and Receive Payments!</h3>
          <ul role="list" class="tw-divide-y tw-divide-gray-n1 tw-p-0">
            <li :class="{'tw-line-through' : completedStepsIds.includes(step.id)}" v-for="(step, index) in steps" v-bind:key="step.id" @click.prevent="navigateTo(step.route)" class="tw-relative tw-bg-white tw-py-5 tw-px-4 hover:tw-bg-gray-n0">
              <div class="tw-flex tw-justify-between tw-space-x-3">
                <div class="tw-min-w-0 tw-flex-1">
                  <a href="#" class="tw-block focus:tw-outline-none tw-text-gray-n4 text-decoration-none">
                    <span class="tw-absolute tw-inset-0" aria-hidden="true"></span>
                    <p class="tw-text-sm tw-font-medium tw-text-gray-n4 tw-truncate tw-m-0">{{ index + 1 }}. {{ step.name }}</p>
                  </a>
                </div>
              </div>
              <div class="tw-mt-1">
                <p class="tw-text-sm tw-text-gray-n2  tw-m-0">{{ step.description }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </v-dialog>
    <v-menu
        offset-y
        left
        min-width="240"
        content-class="tw-shadow tw-mt-4 tw-rounded-lg"
    >
      <template v-slot:activator="{ attrs, on }">
        <div v-bind="attrs" v-on="on" class="tw-rounded-full tw-overflow-hidden tw-h-10 tw-w-10 tw-ml-10">
          <img :src="user.avatar" :alt="user.name" class="tw-h-full tw-w-full tw-object-cover">
        </div>
      </template>

      <div class="tw-bg-white tw-w-60">
        <a @click.prevent="profileCompleteness = true" v-if="steps.length > 0 && user.completeness < 100 && user.vendor" class="tw-flex tw-flex-col tw-text-sm tw-items-center tw-leading-relaxed tw-pb-3.5 tw-text-gray-n4 hover:tw-bg-gray-n0 tw-no-underline" href="#">
          <div class="tw-w-full tw-bg-red-100 tw-h-5 tw-relative">
            <div class="tw-bg-red tw-bg-opacity-40 tw-h-5" :style="{ width: user.completeness + '%' }"></div>
            <span class="tw-absolute tw-w-full tw-h-full tw-top-0 tw-text-center tw-text-xs">{{ user.completeness }}%</span>
          </div>
          <div class="tw-px-4 tw-text-xs tw-mt-2 tw-font-semibold">
            Complete your profile!
          </div>
        </a>
        <router-link class="tw-flex tw-text-sm tw-items-center tw-leading-relaxed tw-py-3.5 tw-px-4 tw-text-gray-n4 hover:tw-bg-gray-n0 tw-no-underline" :to="{name: 'user.my-account'}" v-if="!user.vendor">
          <user-icon class="tw-h-4 tw-w-4 tw-mr-3" />
          {{ $t('general.your_profile') }}
        </router-link>
        <router-link class="tw-flex tw-text-sm tw-items-center tw-leading-relaxed tw-py-3.5 tw-px-4 tw-text-gray-n4 hover:tw-bg-gray-n0 tw-no-underline"
                     :to="{name: 'vendors.profile'}" v-if="user.vendor">
          <user-icon class="tw-h-4 tw-w-4 tw-mr-3" />
          {{ $t('general.your_profile') }}
        </router-link>
        <router-link class="tw-flex tw-text-sm tw-items-center tw-leading-relaxed tw-py-3.5 tw-px-4 tw-text-gray-n4 hover:tw-bg-gray-n0 tw-no-underline"
                     :to="{name: 'vendors.myCompany'}" v-if="user.vendor">
          <home-icon class="tw-h-4 tw-w-4 tw-mr-3" />
          {{ $t('general.company_info') }}
        </router-link>
        <a href="#" class="tw-flex tw-text-sm tw-items-center tw-leading-relaxed tw-py-3.5 tw-px-4 tw-text-red hover:tw-bg-gray-n0 tw-no-underline"
           @click.prevent="logout">
          <log-out-icon class="tw-h-4 tw-w-4 tw-mr-3" />
          {{ $t('auth.logout') }}
        </a>
      </div>
    </v-menu>
  </div>

</template>

<script>

import { mapGetters } from 'vuex'
import { UserIcon, HomeIcon, LogOutIcon } from "vue-feather-icons";

export default {
  components: {
    UserIcon,
    HomeIcon,
    LogOutIcon,
  },
  middleware: 'auth',
  data() {
    return {
      profileCompleteness: false,
      steps: [],
    }
  },
  created() {
    this.getCompletenessSteps();
  },
  computed: {
    ...mapGetters({
     user: 'auth/user',
     check: 'auth/check'
   }),
    completedStepsIds: function() {
      return this.user.completeness_steps.map((item) => item.id);
    }
  },
  methods: {
    getCompletenessSteps() {
      this.$http.get('api/completeness-steps?type=' + (this.user.vendor ? 'vendor' : 'user'))
          .then(({data}) => {
            this.steps = data.data;
          })
          .catch(error => {
            console.log(error);
          });
    },
    navigateTo(routeName) {
      this.$router.push({ name: routeName });
    },
    async logout() {
      // Log out the user.
      //await this.$http.post('api/logout')
      await this.$store.dispatch('auth/signOut')
      //this.$router.push({ name: 'welcome' })
    }
  }
}
</script>
