<template>
<div>

  <dl v-if="promotions.length" class="tw-mt-6 tw-space-y-5">
    <div v-for="(promotion, index) in promotions" v-bind:key="index" class="tw-border tw-py-4 tw-px-6 tw-border-gray-n1 tw-rounded-lg">
      <dt class="tw-text-sm">
        <!-- Expand/collapse question button -->
        <button @click="promotion.expend = !promotion.expend" type="button" class="tw-text-left tw-w-full tw-flex tw-justify-between tw-items-start" aria-controls="faq-0" aria-expanded="false">
                                <span class="tw-font-medium tw-text-gray-900 tw-text-black tw-flex tw-items-center tw-font-semibold">
                                  <span class="tw-font-serif tw-text-2xl tw-mr-6 tw-text-gray-n2 tw-font-normal">{{ String(index + 1).padStart(2, '0') }}</span>
                                  {{ promotion.title }}
                                </span>
          <span class="tw-ml-6 tw-h-7 tw-flex tw-items-center">
                                  <svg :class="[promotion.expend ? 'tw--rotate-180' : 'tw-rotate-0', 'tw-h-3 tw-w-3 tw-transform']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 9" stroke="currentColor" aria-hidden="true">
                                    <path d="M1 1.5L7 7.5L13 1.5" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                  </svg>
                                </span>
        </button>
      </dt>
      <dd class="tw-mt-3 tw-pr-12" id="faq-0" v-show="promotion.expend">
        <div>
          <div class="tw-flex">
            <input v-if="showChecked" id="accepted" type="checkbox" v-model="promotion.accepted"
                   class="tw-flex w-4 h-4 tw-text-red tw-bg-gray-n tw-ring-1 tw-ring-black rounded tw-border-black
                   focus:tw-ring-red dark:focus:tw-ring-red-100 dark:tw-ring-offset-gray-n1 focus:tw-ring-2
                   dark:tw-bg-gray-n1 dark:tw-border-gray-n2" name="accepted"
                   value="1">
            <p class="tw-text-sm tw-flex tw-flex-inline tw-ml-4 ">
              <span v-html="promotion.description"></span>
            </p>
          </div>
          <span v-if="promotion.date_from">Valid from: {{dateFormat(promotion.date_from)}}</span>
          <span v-if="promotion.date_to"> until: {{dateFormat(promotion.date_to)}}</span>
          <div v-if="promotion.terms">
            <div v-if="showTerms">
            <p class="tw-mt-2 tw-text-xs tw-italic"><strong>{{$t('legal.terms')}}:</strong> <span v-html="promotion.terms"></span></p>
            </div>
            <slot name="terms_link" />
          </div>
        </div>
      </dd>
    </div>
  </dl>

</div>
</template>

<script>

export default {
    name: 'promotions',
    props: {
      showTerms: {
        default: false,
        type: Boolean
      },
      showChecked: {
        default: true,
        type: Boolean
      }
    },
    components:{

    },
    data() {
      return {
        promotions: []
      }
    },
    computed: {

    },
    watch:{
      promotions: function () {
        this.$emit('selectedPromotions', this.promotions);
      },
    },
    mounted() {
      this.getPromotions();
    },
    methods: {
      getPromotions(){

        this.$http.get('/api/promotions')
            .then(({data}) => {
              this.promotions = data.data.filter(promotion => promotion.show_in_cart === true);
            })
            .catch(error => {
              console.log(error)
            });

      },
      }
};
</script>


