<template>
  <div>
    <div class="tw-flex tw-flex-row tw-items-center tw-justify-items-center tw-justify-end tw-mb-2">

      <div v-if="showAddressFilter == true" class="tw-h-4 tw-flex tw-flex-row tw-items-center tw-justify-items-center tw-justify-end">
        <div v-for="(type,index) in this.getUniqueTypes(this.items)" :key="index" 
              class="tw-items-center tw-justify-items-center tw-mb-2">
                <div class="tw-flex tw-items-center tw-justify-items-center">
                    <input id="vue-checkbox-list" type="checkbox" v-model="filterByTypes" checked :value="type.id" 
                    class="tw-w-3 tw-h-3 tw-text-black tw-bg-gray-n3 tw-rounded tw-ml-2 tw-cursor-pointer">
                    <span for="vue-checkbox-list" class="tw-ml-1 tw-text-xs tw-font-xs tw-text-gray-n3">{{type.name}}</span>
                </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-justify-items-center tw-h-4">
        <span @click="showAddressFilter = !showAddressFilter"
              class="tw-cursor-pointer tw-text-gray-n2 tw-text-xs tw-inline-flex tw-items-center"
              >
          <filter-icon class="tw-w-4 tw-h-4 tw-ml-3" 
          :class="this.filterByTypes.length != this.types.length ? 'tw-text-orange-400':'tw-text-gray-n4'"
          />
        </span>
      </div>

    </div>
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <!--
        .filter(item => item.valid)
        -->
      <div class="tw-col-span-1 tw-rounded-xl tw-border-gray-n1 tw-border tw-overflow-hidden tw-flex tw-items-center" 
      v-for="(address,index) in items.filter(item => this.filterByTypes.includes(item.type.id))"
          :key="index">
        <slot name="address"
              :address="address"
        ></slot>
      </div>

      <slot name="newAddress" />
    </div>

  </div>

</template>

<script>
import { FilterIcon } from 'vue-feather-icons'

export default {
  components: {
    FilterIcon
  },
  props: {
    gridClass: {
      type: String,
      default: '',
    }
  },
  middleware: 'user',

  data() {
    return {
      loading: false,
      items: [],
      createAddress: false,
      updateAddress: false,
      selectIdEditAddress: null,
      primaryAddress: null,
      filterByTypes: [],
      showAddressFilter: true
    }
  },

  mounted() {
    this.getAddress();
  },
  watch: {
    "$route": 'getAddress',
  },
  computed: {
    types(){
      const array = this.items.map(a => a.type);
      const key = 'id';
      const arrayUniqueByKey = [...new Map(array.map(item => [item[key], item])).values()];
      return arrayUniqueByKey;
    }
  },
  methods: {
    returnPrimaryAddress(data) {
      if (data) {
        //this.primaryAddress = data.filter(item => item.default_address === true && item.valid)[0];
        this.primaryAddress = data.filter(item => item.valid)[0];

        if(!this.primaryAddress){
          this.primaryAddress = data[0];
        }

        this.$emit('selectedAddress', {
          item: this.primaryAddress
        });
      }
      return this.primaryAddress;
    },
    getUniqueTypes(items){
      const array = items.map(a => a.type);
      const key = 'id';
      const arrayUniqueByKey = [...new Map(array.map(item => [item[key], item])).values()];
      return arrayUniqueByKey;
    },    
    edit(id) {

      this.selectIdEditAddress = id;
      this.updateAddress = true;
      return true;
      //this.$router.push({name: 'address.edit', params: {id: id}})
    },
    create() {
      this.createAddress = true;
    },
    async store(data) {

      this.createAddress = false;
      this.loading = true;
      let item = data.item;
      await this.$http.post('/api/address', item)
          .then((response) => {
            this.$toast.success(response.data.message, "Success");
            this.getAddress();
          })
          .catch((error) => {
            console.log('error', error.data.error);
            this.$toast.error(error.data.error, "Error");
          });
      this.loading = false;
    },
    async update(data) {
      this.updateAddress = false;
      this.loading = true;
      let item = data.item;
      await this.$http.patch('/api/address/' + item.id, item)
          .then(({data}) => {
            this.getAddress();
            this.$toast.success(data.message, "Success");
          })
          .catch((error) => {
            this.getAddress();
            this.$toast.warning(error.data.message, "Warning");
          })
      this.loading = false;
    },
    delete(item) {

      let uri = '/api/address/' + item.id;
      this.$http.delete(uri)
          .then((response) => {
            this.getAddress();
            this.$toast.success(response.data.message, "Success");
          }).catch(error => {
        //console.log(error);
        this.$toast.warning(error.data.message, "Warning")
      });

    },
    destroy(item) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.delete(item);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    setDefault(item) {

      let uri = '/api/address/' + item.id;
      item.default_address = 1;
      item.country_id = item.country.id;
      /*
      let value = {
        id: item.id,
        default_address: 1
      }
      */
      this.$http.patch(uri, item)
          .then((response) => {
            this.getAddress();
            this.$toast.success(response.data.message, "Success");
          })
          .catch(() => {
            item.default_address = 0;
          });

    },
    async getAddress() {

      this.loading = true;

      const params = {};
      await this.$http.get('/api/address', {params})
          .then(({data}) => {
            this.items = data.data;
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() =>
              this.loading = false,
          );
      
      this.filterByTypes = this.getUniqueTypes(this.items).map(a => a.id)
      this.returnPrimaryAddress(this.items);

    },
  }
}
</script>
