<template>
  <div :class="{'tw-bg-red-100': red}">
<!--
guestGhost: {{guestGhost}}
   Ghost: {{ghost}}
   Guest: {{guest}}
    Logged in: {{check}}
-->
    <!-- Side bar -->
    <v-navigation-drawer v-model="sidebar" app v-if="sidebar">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <Logo/>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>

          <!-- User settings menu -->
          <template v-if="user && !ghost && !guest">
            <Account/>
          </template>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense nav>
        <!-- Auth null -->
        <v-list-item
            class="routerLink"
            v-for="item in menuItems.filter(item => item.auth == null)"
            :key="item.title"
            link
            :to="{ name: item.name }"
            :disabled="item.disabled"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>

          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item
            class="routerLink"
            href="https://wpt.mangolola.com/"
            link
            target="_blank"
        >
          <v-list-item-icon>
            <v-icon></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Wedding Budget Tool</v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->
        <v-list-item
            class="routerLink"
            :to="{ name: 'feed' }"
            link
        >

          <v-list-item-content>
            <v-list-item-title>{{ $t('navbar.book_a_vendor') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group
            class="routerLink ml-menu-fix-color"
            :value="true"
        >
          <template v-slot:activator>
            <v-list-item-title>{{ $t('navbar.couples') }}</v-list-item-title>
          </template>

          <v-list-item
              class="routerLink"
              :to="{ name: 'usersintro' }"
              link
          >

            <v-list-item-content>
              <v-list-item-title>Mango & Lola for Couples</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              class="routerLink"
              :to="{ name: 'founder' }"
              link
          >

            <v-list-item-content>
              <v-list-item-title>Our Founder</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              class="routerLink"
              :to="{ name: 'how-to-use-couples' }"
              link
          >

            <v-list-item-content>
              <v-list-item-title>How to Use Mango & Lola</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              class="routerLink"
              href="https://spaces.mangolola.com/faq/couples/Mango%20&%20Lola%20The%20First%205%20Things%20You%20Need%20to%20Do.pdf"
              target="_blank"
              link
          >

            <v-list-item-content>
              <v-list-item-title>The First 5 Things You Need to Do</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              class="routerLink"
              href="https://spaces.mangolola.com/faq/couples/Mango%20&%20Lola%20Wedding%20Planning%20Timeline.pdf"
              target="_blank"
              link
          >

            <v-list-item-content>
              <v-list-item-title>Wedding Planning Timeline</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
            class="routerLink"
            href="https://blog.mangolola.com"
            link
            target="_blank"
        >

          <v-list-item-content>
            <v-list-item-title>Ideas & Advice</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            class="routerLink"
            :to="{name: 'vendorsintro'}"
            link
        >

          <v-list-item-content>
            <v-list-item-title>Wedding Pros Join Here</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Auth  false -->
        <template v-if="!user">
          <v-list-item
              v-for="item in menuItems.filter(item => item.auth == false)"
              :key="item.title"
              link
              :to="{ name: item.name }"
              :disabled="item.disabled"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </template>

        <!-- Auth  True -->
        <template v-if="user">

          <!-- Type User -->
          <template v-if="user.vendor === false">
            <v-list-item
                class="routerLink"
                v-for="item in menuItems.filter(item => item.type == 'user' || item.type == 'feed')"
                :key="item.title"
                link
                :to="{ name: item.name }"
                :disabled="item.disabled"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </template>

          <!-- Type Vendor -->
          <template v-if="user.vendor === true">
            <v-list-item
                class="routerLink"
                v-for="item in vendorMenuItems.filter(item => item.type == 'vendor' || item.type == 'feed')"
                :key="item.title"
                link
                :to="{ name: item.name }"
                :disabled="item.disabled"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>

              </v-list-item-content>
            </v-list-item>
          </template>

        </template>

      </v-list>
    </v-navigation-drawer>

    <!-- Top bar -->
    <div class="tw-max-w-9xl tw-mx-auto tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-w-full sm:tw-mb-7">
      <v-toolbar elevation="0" height="78" class="no-toolbar-p" :class="{'tw-bg-red-100': red}">
        <span class="hidden-md-and-up">
          <v-app-bar-nav-icon dense @click="sidebar = !sidebar"></v-app-bar-nav-icon>
        </span>
        <Logo class="hidden-sm-and-down"/>
        <v-spacer></v-spacer>

        <!-- ALL -->
        <template class="hidden-sm-and-down">
          <v-toolbar-items v-if="(!user || user.guest) && !authPages()" class="hidden-sm-and-down tw-items-center tw-font-semibold tw-space-x-10">
            <router-link
                class="hover:tw-text-black tw-no-underline tw-text-gray-n3"
                exact
                :to="{ name: 'feed' }"
                v-slot="{ href, navigate }"
                custom
            >
              <a :href="href" @click="navigate">{{ $t('navbar.book_a_vendor') }}</a>
            </router-link>
            <v-menu offset-y content-class="tw-bg-transparent ml-fix-dropdown" v-model="coupleMenu">
              <template v-slot:activator="{ on, attrs }">
                <a
                    class="hover:tw-text-black tw-no-underline tw-text-gray-n3"
                    v-bind="attrs"
                    v-on="on"
                >
                  {{ $t('navbar.couples') }}
                </a>
              </template>
              <div class="tw-mt-2 tw-w-56 tw-origin-top-right tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="tw-py-1" role="none">
                  <router-link
                      class="hover:tw-text-black tw-no-underline"
                      exact
                      :to="{ name: 'usersintro' }"
                      v-slot="{ href, navigate }"
                      custom
                  >
                    <a :href="href" @click="navigate" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">Mango & Lola for Couples</a>
                  </router-link>
                  <router-link
                      class="hover:tw-text-black tw-no-underline"
                      exact
                      :to="{ name: 'founder' }"
                      v-slot="{ href, navigate }"
                      custom
                  >
                    <a :href="href" @click="navigate" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">Our Founder</a>
                  </router-link>
                  <router-link
                      class="hover:tw-text-black tw-no-underline"
                      exact
                      :to="{ name: 'how-to-use-couples' }"
                      v-slot="{ href, navigate }"
                      custom
                  >
                    <a :href="href" @click="navigate" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">How to Use Mango & Lola</a>
                  </router-link>
<!--                  <router-link
                      class="hover:tw-text-black tw-no-underline"
                      exact
                      :to="{ name: 'first-five-things' }"
                      v-slot="{ href, navigate }"
                      custom
                  >
                    <a :href="href" @click="navigate" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">The First 5 Things You Need to Do</a>
                  </router-link>-->
                  <a href="https://spaces.mangolola.com/faq/couples/Mango%20&%20Lola%20The%20First%205%20Things%20You%20Need%20to%20Do.pdf" target="_blank" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">The First 5 Things You Need to Do</a>
                  <a href="https://spaces.mangolola.com/faq/couples/Mango%20&%20Lola%20Wedding%20Planning%20Timeline.pdf" target="_blank" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">Wedding Planning Timeline</a>
                  <router-link
                      class="hover:tw-text-black tw-no-underline"
                      exact
                      :to="{ name: 'feed' }"
                      v-slot="{ href, navigate }"
                      custom
                  >
                    <a :href="href" @click="navigate" class="tw-text-gray-n3 hover:tw-text-black tw-block tw-px-4 tw-py-2 tw-text-sm">{{ $t('navbar.book_a_vendor') }}</a>
                  </router-link>
                </div>
              </div>
            </v-menu>
<!--            <router-link
                class="hover:tw-text-black tw-no-underline"
                exact
                :to="{ name: 'vendorsintro' }"
                v-slot="{ href, navigate, isActive, isExactActive }"
                custom
            >
              <a :class="[isActive && 'router-link-active', isExactActive && 'tw-text-black', !isExactActive && 'tw-text-gray-n3']" :href="href" @click="navigate">{{ $t('navbar.vendors') }}</a>
            </router-link>-->
<!--            <template v-for="item in menuItems.filter(item => item.type === 'landing' && item.auth == null)" >
              <router-link
                  class="hover:tw-text-black tw-no-underline"
                  exact
                  :to="{ name: item.name }" :key="item.title"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  custom
              >
                <a :class="[isActive && 'router-link-active', isExactActive && 'tw-text-black', !isExactActive && 'tw-text-gray-n3']" :href="href" @click="navigate">{{ $t(item.title) }}</a>

              </router-link>

            </template>
            <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="https://wpt.mangolola.com/">Wedding Budget Tool</a>

            </template>-->
<!--        <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="#" @click.prevent="openBudgetTeaser = true">Wedding Budget Tool</a>-->


            <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="https://blog.mangolola.com">Ideas & Advice</a>
          </v-toolbar-items>
          <v-toolbar-items v-if="user && !user.vendor" class="tw-mr-2 tw-ml-10 hidden-sm-and-down tw-items-center tw-font-semibold tw-space-x-10">
            <template v-for="item in menuItems.filter(item => item.type === 'feed' && item.guest === user.ghost)" >
              <router-link
                  class="hover:tw-text-black tw-no-underline"
                  exact
                  :to="{ name: item.name }" :key="item.title"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  custom
              >
                <a :class="[isActive && 'router-link-active', isExactActive && 'tw-text-black', !isExactActive && 'tw-text-gray-n3']" :href="href" @click="navigate">{{ $t(item.title) }}</a>
              </router-link>
            </template>

<!--            <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="#" @click.prevent="openBudgetTeaser = true">Wedding Budget Tool</a>-->

            <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="https://blog.mangolola.com">Ideas & Advice</a>
          </v-toolbar-items>
          <v-toolbar-items v-if="user && user.vendor" class="hidden-sm-and-down tw-items-center tw-font-semibold tw-space-x-10">
            <template v-for="item in vendorMenuItems" >
              <router-link
                  class="hover:tw-text-black tw-no-underline"
                  exact
                  :to="{ name: item.name }" :key="item.title"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  custom
              >
                <a :class="[isActive && 'router-link-active', isExactActive && 'tw-text-black', !isExactActive && 'tw-text-gray-n3']" :href="href" @click="navigate">{{ $t(item.title) }}</a>
              </router-link>
            </template>
<!--            <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="#" @click.prevent="openBudgetTeaser = true">Wedding Budget Tool</a>-->
            <a class="tw-text-gray-n3 hover:tw-text-black tw-no-underline" href="https://blog.mangolola.com">Ideas & Advice</a>
          </v-toolbar-items>
        </template>
        <v-spacer></v-spacer>



        <!-- Feed Search & Sort -->
        <transition name="slide-fade" mode="out-in">
          <div class="tw-mb-6 sm:tw-mt-6 tw-mt-6 tw-fixed tw-left-0 tw-z-50 tw-w-full tw-bg-white" v-if="showSearch && !authPages()">
            <div class="tw-flex align-items-center">
              <div class="tw-flex-grow">
                <Search></Search>
              </div>
              <span class="tw-ml-4 tw-cursor-pointer" @click="showSearch = false">
                <x-icon class="tw-w-6 tw-h-6"/>
              </span>
            </div>
          </div>
        </transition>

        <div v-if="!authPages()" class="tw-text-gray-n3 tw-mr-4 tw-cursor-pointer" @click="showSearch=true">
          <search-icon class="tw-w-5 tw-h-5" />
        </div>

        <!-- Authenticated -->
        <template v-if="user" class="hidden-sm-and-down ">

          <!-- Vendor menu -->
          <v-toolbar-items
              v-if="user.is_activated === true && user.is_it_boarded === false && user.vendor === true"
              class="hidden-sm-and-down tw-items-center tw-font-semibold tw-space-x-10">

              <router-link
              :to="{ name: 'vendor.onboarding' }"
              class="hover:tw-text-black tw-no-underline"
              v-slot="{ href, navigate, isActive, isExactActive }"
              custom
             >
              <a :class="[isActive && 'router-link-active', isExactActive && 'tw-text-black',
              !isExactActive && 'tw-text-gray-n3']" :href="href" @click="navigate">
                {{ $t('navbar.letsgetstarted') }}
              </a>

              </router-link>

          </v-toolbar-items>

          <!-- User settings menu -->
          <v-toolbar-items v-if="user.vendor === false" class="tw-items-center tw-mr-2">
            <Cart/>
          </v-toolbar-items>
            <div class="tw-flex tw-items-center" v-if="user && !ghost && !guest">
              <div class="tw-relative tw-ml-10">
                <router-link :to="{ name: 'messages' }">
                  <message-square-icon class="tw-w-5 tw-text-brown" stroke-width="1" />
                  <div class="tw-bg-red tw-w-3.5 tw-h-3.5 tw-rounded-full tw-flex tw-items-start tw-justify-center tw-absolute tw--right-0.5 tw-top-0 tw-text-white tw-font-semibold" style="font-size: 8px">
                    {{ user.countMessages }}
                  </div>
                </router-link>
              </div>
              <Notification />
              <Account />
            </div>
          <!-- User Menu -->
          <template v-if="1==2">
            <v-toolbar-items v-if="user.vendor === false" class="hidden-sm-and-down">
              <v-menu
                  offset-y
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon left>icon-setting</v-icon>
                    {{ user.name }}
                  </v-btn>
                </template>

                <v-list dense nav>
                  <v-list-item
                      class="routerLink"
                      v-for="item in menuItems.filter(item => item.type === 'user')"
                      :key="item.title"
                      link
                      :to="{ name: item.name }"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title> {{ $t(item.title) }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>
          </template>

        </template>

        <!-- Guest -->
        <template v-if="!check || (guest || ghost) && !authPages()" class="hidden-sm-and-down">
          <v-toolbar-items class="hidden-sm-and-down tw-justify-center tw-mt-6 tw-font-semibold tw-flex tw-flex-col">
            <div class="tw-space-x-4">
              <template v-for="item in menuItems.filter(item => item.auth === false && item.type === 'landing' && !item.alt)" >
                <router-link class="tw-inline-flex tw-no-underline tw-items-center tw-px-8 tw-py-3 tw-border tw-border-black tw-shadow-sm tw-leading-4 tw-font-semibold tw-rounded-md tw-text-black" :to="{ name: item.name }" :key="item.title">
                  {{ $t(item.title) }}
                </router-link>
              </template>
              <template v-for="item in menuItems.filter(item => item.auth === false && item.type === 'landing' && item.alt)" >
                <router-link class="tw-inline-flex tw-no-underline tw-items-center tw-bg-black tw-px-8 tw-py-3 tw-border tw-border-black tw-shadow-sm tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white" :to="{ name: item.name }" :key="item.title">
                  {{ $t(item.title) }}
                </router-link>
              </template>
            </div>
            <router-link class="tw-text-black tw-text-sm" :to="{ name: 'vendorsintro' }">Wedding Pros Join Here</router-link>
          </v-toolbar-items>
        </template>
        <template v-else-if="!check && authPages()" class="tw-ml-2 tw-mr-2 hidden-sm-and-down">
          <span class="tw-text-gray-n3 tw-leading-relaxed">
            <template v-if="$route.name === 'login'">
              <router-link class="tw-text-black" :to="{ name: 'register' }"><span class="tw-text-gray-n3">{{ $t('auth.dont_have_account') }}</span> {{ $t('auth.register') }}.</router-link>
            </template>
            <template v-else>
              <router-link class="tw-text-black" :to="{ name: 'login' }"><span class="tw-text-gray-n3">{{ $t('auth.already_have_account') }}</span> {{ $t('auth.signin') }}</router-link>
            </template>
          </span>
        </template>

        <!--
      <LanguageSwitcher />
      -->
      </v-toolbar>
    </div>

    <BudgetToolTeaser :open-modal="openBudgetTeaser" v-on:closeModal="openBudgetTeaser = false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Account from './Account.vue'
import Logo from './Logo.vue'
import Cart from './Cart.vue'
import Notification from './Notification.vue'
import Search from './Search'
import i18n from '@/i18n'
import { MessageSquareIcon, XIcon, SearchIcon } from "vue-feather-icons";
import BudgetToolTeaser from "@/components/BudgetToolTeaser.vue";

//{{ $t('logout') }}

export default {
  props: ['red'],
  components: {
    BudgetToolTeaser,
    //LanguageSwitcher,
    Search,
    Account,
    Notification,
    Cart,
    Logo,
    MessageSquareIcon,
    XIcon,
    SearchIcon,
  },

  data: () => ({
    coupleMenu: false,
    openBudgetTeaser: false,
    appName: process.env.VUE_APP_TITLE, //this.appName //window.config.appName
    fav: true,
    menu: false,
    Myaccount: false,
    sidebar: false,
    showSearch: false,
    authPagesList: [
      'login',
      'register',
      'password.request',
      'password.reset',
    ],
    vendorMenuItems: [
      {
        disabled: false,
        auth: true,
        type: 'feed',
        title: i18n.t('navbar.wedding_offers'),
        path: '/feed',
        name: 'feed',
        icon: 'mdi-home'
      },
      {
        disabled: false,
        auth: true,
        type: 'vendor',
        title: i18n.t('navbar.vendor_dashboard'),
        path: '',
        name: 'vendors.dashboard',
        icon: 'mdi-account'
      },
      {
        disabled: false,
        auth: true,
        type: 'vendor',
        title: i18n.t('navbar.vendor_faq_video'),
        path: '',
        name: 'vendors.faq.videos',
        icon: 'mdi-account'
      },
    ],
    menuItems: [
      /*{
        disabled: false,
        auth: null,
        type: 'landing',
        title: i18n.t('navbar.book_a_vendor'),
        path: '/feed',
        name: 'feed',
        icon: 'mdi-home'
      },*/
      /*{disabled: false, auth: null, type: 'landing', title: i18n.t('navbar.about'), path: '', name: 'about', icon: ''},*/
      {
        disabled: false,
        auth: true,
        type: 'feed',
        title: i18n.t('navbar.book_vendor'),
        path: '/feed',
        name: 'feed',
        icon: 'mdi-home',
        guest: true
      },
        /*
      {
        disabled: true,
        auth: null,
        type: 'landing',
        title: i18n.t('navbar.couples'),
        path: '',
        name: 'usersintro',
        icon: '',
        guest: true
      },
         */
      /*{
        disabled: false,
        auth: null,
        type: 'landing',
        title: i18n.t('navbar.vendors'),
        path: '',
        name: 'vendorsintro',
        icon: '',
        guest: true
      },*/
      /*{
        disabled: false,
        auth: null,
        type: 'landing',
        title: i18n.t('navbar.faq'),
        path: '',
        name: 'faq',
        icon: '',
        guest: true
      },*/
      {
        disabled: false,
        auth: false,
        type: 'landing',
        title: i18n.t('auth.signin'),
        path: '',
        name: 'login',
        icon: 'mdi-user',
        guest: true
      },
      {
        disabled: false,
        auth: false,
        type: 'landing',
        alt: true,
        title: i18n.t('auth.sign_up'),
        path: '',
        name: 'register',
        icon: 'mdi-user',
        guest: true
      },


      /*
                { disabled: false, auth: false, type: 'all', title: 'Couples', path: '', name: 'usersintro', icon: '' },
                { disabled: false, auth: false, type: 'all', title: 'Vendors', path: '', name: 'vendorsintro', icon: '' },
                { disabled: false, auth: null, type: 'all', title: 'About Us', path: '', name: 'about', icon: '' },
      */
      /*
      { disabled: false, auth: true, type: 'user', title: 'My Account', path: '', name: 'user.my-account', icon: 'icon-setting' },
      { disabled: false, auth: true, type: 'user', title: 'My wedding', path: '', name: 'user.myevent', icon: 'mdi-account-multiple' },
      { disabled: false, auth: true, type: 'user', title: 'My vendors', path: '', name: 'user.myvendors', icon: 'mdi-basket-fill' },
      { disabled: false, auth: true, type: 'user', title: 'Quote requests', path: '', name: 'user.quotes', icon: 'mdi-account-cash' },
      { disabled: false, auth: true, type: 'user', title: 'My orders', path: '', name: 'orders', icon: 'mdi-calendar-month' },
      { disabled: true, auth: true, type: 'vendor', title: 'Dashboard', path: '', name: 'vendors.dashboard', icon: 'mdi-table' },

      { disabled: false, auth: true, type: 'vendor', title: 'My company', path: '', name: 'vendors.myCompany', icon: 'mdi-account' },
      { disabled: false, auth: true, type: 'vendor', title: 'offerings', path: '', name: 'vendor.offers', icon: 'mdi-package-variant' },
      { disabled: false, auth: true, type: 'vendor', title: 'Calendar', path: '', name: 'vendors.calendar', icon: 'mdi-calendar-month' },

      { disabled: false, auth: true, type: 'vendor', title: 'Quote requests', path: '', name: 'vendor.quotes', icon: 'mdi-account-cash' },
      { disabled: false, auth: true, type: 'vendor', title: 'Bookings', path: '', name: 'vendor.bookings', icon: 'mdi-calendar-month' },
      { disabled: false, auth: true, type: 'vendor', title: 'Settings', path: '', name: 'vendors.settings', icon: 'mdi-shape-plus' },
      */
      {
        disabled: false,
        auth: true,
        type: 'vendor',
        title: i18n.t('navbar.vendor_dashboard'),
        path: '',
        name: 'vendors.dashboard',
        icon: 'mdi-account',
        guest: false
      },
      {
        disabled: false,
        auth: true,
        type: 'feed',
        title: i18n.t('navbar.user_dashboard'),
        path: '',
        name: 'user.dashboard',
        icon: 'mdi-account',
        guest: false
      },
      {
        disabled: false,
        auth: true,
        type: 'feed',
        title: i18n.t('user.navbar.my_vendors'),
        path: '',
        name: 'user.myvendors',
        icon: 'mdi-account',
        guest: false
      },
    ]
  }),

  computed: {
    ...mapGetters({
      user: 'auth/user',
      check: 'auth/check',
      guestGhost: 'auth/guestGhost',
      ghost: 'auth/ghost',
      guest: 'auth/guest'
    }),
    currentRouteName() {
      return this.$router.history.current.path;
    },
  },
  mounted() {

  },
  methods: {
    authPages() {
      return this.authPagesList.includes(this.$route.name);
    }
  }

}
</script>


<style scoped>
.underline {
  text-decoration: underline;
}

.underline i.material-icons {
  text-decoration: none !important;
}
</style>
