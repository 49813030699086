<template>
  <div>
    <v-alert
        v-if="this.confirmPaymentError"
        prominent
        border="left"
        color="warning"
        outlined
    >
      <v-row align="center">
        <v-col>
          {{ this.confirmPaymentError.message }}
        </v-col>
        <v-col class="shrink">
          <v-btn small text @click="goBack()">{{ $t('cart.back') }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <ml-alert v-if="orderError.length" class="tw-mb-2">
      <template v-slot:title>
        {{ orderError['message'] }}
      </template>
      <template v-slot:msg>
        {{ orderError['error'] }}
        {{ orderError['errors'] }}
      </template>
    </ml-alert>

    <v-progress-linear
        v-show="loading"
        indeterminate
        color="yellow"
        height="2"
        class="tw-mb-2"
    ></v-progress-linear>
    <div class="tw-mb-3" v-if="listPaymentMethods && listPaymentMethods.length > 0">
        <v-radio-group
            v-model="selectedPaymentMethodId"
            column
        >
          <v-radio
              v-for="(pm,index) in listPaymentMethods" :key="index"
              :label="pm.card.brand + '|' + pm.card.last4 + '|' + pm.card.exp_year + '/' + pm.card.exp_month"
              :value="pm.id"
              color="#2D2D2D" :ripple="false"
              class="tw-text-radio-sm"
          ></v-radio>
        </v-radio-group>
    </div>
    <div class="tw-font-semibold tw-text-black tw-leading-relaxed tw-mb-2 tw-text-sm">
      Add a new payment option
    </div>
    <div id="payment-element"></div>
  </div>
</template>
<script>

import { loadStripe } from '@stripe/stripe-js';
import MlAlert from "@/components/layout/MlAlert";

export default {
  props: ['user', 'address', 'paymentProceed', 'total', 'cartItems', 'selectedVendorPaymentOption', 'newOrder', 'client_secret', 'paymentUrl', 'message', 'order'],
  components:{
    MlAlert
  },
  data() {
    return {
      stripe_pk: process.env.VUE_APP_STRIPE_PK,
      validInputs: false,
      stripe: {},
      cardElement: {},
      loading: true,
      orderResponse: null,
      orderError: [],
      elements: null,
      card: null,
      listPaymentMethods: null,
      newPaymentMethodId: null,
      fingerprint: null,
      duplicates: null,
      selectedPaymentMethodId: null,
      paymentProcessing: false,
      setupIntent: null,
      confirmPaymentError: null,
      client_secret1: null,
      orderIntent: {
        cartItems: {},
        setupIntent: null
      },
      error: null,

    }
  },
  async mounted() {

    this.loadStripe();
    this.getPaymentMethods();

  },
  watch: {
    "paymentProceed": function () {
      // console.log("---");
      // console.log(this.paymentProceed);
      // console.log(this.validInputs);
      // console.log(!this.paymentProcessing);

      if (this.paymentProceed && this.validInputs && !this.paymentProcessing) {
        //console.log("processPayment");
        this.processPayment();
      }
    },
    "setupIntent": function () {
      this.processOrder();
    },
    "selectedPaymentMethodId": function () {
      if (this.selectedPaymentMethodId) {
        this.validPaymentInputs(true);
      } else {
        this.validPaymentInputs(false);
      }
    },
  },
  methods: {

    async loadStripe() {

      this.loading = true;

      // get client secret to load stripe
      let url = '/api/stripe/SetupIntent';
      await this.$http.post(url)
          .then((response) => {
            this.clientSecret1 = response.data.client_secret;
          })
          .catch((error) => {
            console.error(error);
          })

      // load stripe
      var _self = this;
      this.stripe = await loadStripe(this.stripe_pk);

      /*
      // set client secret
      const options = {
        clientSecret: _self.clientSecret1,
        // Fully customizable with appearance API.
        appearance: {},
      };
      */


      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
      //this.elements = this.stripe.elements(options);
      this.elements = this.stripe.elements();


      // Create and mount the Payment Element
      const paymentElement = this.elements.create('card');
      await paymentElement.mount('#payment-element');

      this.card = paymentElement;


      // validate card details
      paymentElement.on('change', function (event) {

        _self.selectedPaymentMethodId = null;
        if (event.complete) {
          // enable payment button
          //console.log("enable payment button");
          _self.validPaymentInputs(true);
        } else if (event.error) {
          // show validation to customer
          //console.log("show validation to customer");
          _self.validPaymentInputs(false);
        }
      });


      /*
                          if(this.newOrder){

                            let url = '/api/orders';
                            await this.$http.post(url,this.cartItems)
                              .then((response) => {
                                  options.clientSecret = response.data.client_secret;
                                  this.orderResponse = {
                                    'message' : response.data.message,
                                    'order': response.data.order
                                  };
                                  //console.log(response);
                              })
                              .catch((error) => {
                                this.orderError = error;
                                  console.error(error);
                              })

                            }else{
                              options.clientSecret = this.client_secret;
                                  this.orderResponse = {
                                    'message' : this.message,
                                    'order': this.order
                                  };
                            }
                          //options.clientSecret = 'pi_3K2LjZLulrKNnlEH0UZeMOE2_secret_FDieGjXIgnU5WvPzrWzA0SEjl';
      */


      /* ---

         const elements = this.stripe.elements();

           var elementStyles = {
             base: {
               color: "#32325D",
               fontWeight: 500,
               fontFamily: "Inter, Open Sans, Segoe UI, sans-serif",
               fontSize: "16px",
               fontSmoothing: "antialiased",

               "::placeholder": {
                 color: "#CFD7DF"
               }
             },
             invalid: {
               color: "#E25950"
             }
           };

           var elementClasses = {
             focus: 'focused',
             empty: 'empty',
             invalid: 'invalid',
           };

           this.cardElement = elements.create('card', {
             style: elementStyles,
             classes: elementClasses,
           });

           this.cardElement.mount('#card-element');

           var _self = this;
           this.cardElement.on('change', function(event) {
           if (event.complete) {
             // enable payment button
             //console.log("enable payment button");
             _self.validPaymentInputs(true);
           } else if (event.error) {
             // show validation to customer
             //console.log("show validation to customer");
             _self.validPaymentInputs(false);
           }
         });

     */

    },
    goBack() {
      this.$emit('goback', {
        paymentProcessing: this.paymentProcessing
      });
    },
    validPaymentInputs(val) {
      this.validInputs = val;
      this.$emit('validPaymentInputs', {
        validPaymentInputs: val
      });
    },
    async processOrder() {

      let url = '/api/orders';
      await this.$http.post(url, this.cartItems)
          .then((response) => {
            this.orderResponse = response.data;
            this.$emit('ordered', {
              response: {
                'orderResponse': this.orderResponse,
                'paymentIntent': this.setupIntent
              }
            })
          })
          .catch((error) => {
            this.orderError.push(error.data.message);
            this.orderError['message'] = error.data.message;
            this.$emit('orderFailed', error);
          });




    },
    async processPayment() {

      this.paymentProcessing = true;
      this.$emit('paymentProcessing', {
        paymentProcessing: this.paymentProcessing
      });
      this.loading = true;

      var _self = this;
      //const elements = this.elements;
      //const return_url = this.$route.query.page; //window.location.origin;

      //confirmSetup

      /*

        await this.stripe.confirmSetup({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required',
            confirmParams: {
              //return_url: return_url,
            }
          }).then(function(result) {

          if (result.error) {
              _self.confirmPaymentError = result.error;
              _self.paymentProcessing = false;
              _self.$emit('paymentProcessing', {
                paymentProcessing: _self.paymentProcessing,
                confirmPaymentError: result.error
              });
              _self.loading=false;
              //console.error(error);
          } else {

                  _self.setupIntent = result.setupIntent;
                  _self.cartItems.setupIntent = _self.setupIntent;
                  _self.confirmPaymentError = null;

                _self.$emit('paymentProcessing', {
                  paymentProcessing: _self.paymentProcessing,
                  orderError: false
                });


                _self.loading=false;
              // Your customer will be redirected to your `return_url`. For some payment
          }

        });

      */

      //console.log(_self.clientSecret1);
      //console.log(_self.setupIntent.payment_method)
      if (!this.selectedPaymentMethodId) {

        // Create new PM
        await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.card,
        }).then(function (result) {
          if (result.error) {
            _self.confirmPaymentError = result.error;
            _self.paymentProcessing = false;
            _self.loading = false;
            //console.error(error);
          } else {
            //console.log("Created Payment Method", result.paymentMethod);
            _self.newPaymentMethodId = result.paymentMethod.id;
          }
        });

        // After new PM is created, check if duplicate exist, if yes, select on of them
        if (this.newPaymentMethodId && !this.confirmPaymentError) {
          let url = 'api/stripe/paymentMethods/' + this.newPaymentMethodId;
          await this.$http.get(url)
              .then(({data}) => {
                this.fingerprint = data.data[0].card.fingerprint;
                //console.log("Check crated Payment Method / fingerprint:",this.fingerprint);
              })
              .catch(error => {
                this.error = error
              });

          // Check if duplicate exist, if yes, select on of them
          if (this.fingerprint && this.listPaymentMethods && !this.error) {
            //console.log("Proces duplicates...");
            const duplicates = this.listPaymentMethods.filter((pm) => pm.card.fingerprint === this.fingerprint);
            this.duplicates = duplicates;
            //console.log("duplicates",duplicates);
            //console.log("duplicates length",duplicates.length);
            if (duplicates.length > 0) {
              this.newPaymentMethodId = duplicates[0].id;
              //console.log("Duplicates",duplicates[0].id);
            }

          }


        }


      } else {
        this.newPaymentMethodId = this.selectedPaymentMethodId;
      }

      // Confirm (commit) card setup and payment for Order
      await this.stripe.confirmCardSetup(_self.clientSecret1, {
        payment_method: _self.newPaymentMethodId
      }).then(function (result) {

        if (result.error) {
          _self.confirmPaymentError = result.error;
          _self.paymentProcessing = false;
          _self.$emit('paymentProcessing', {
            paymentProcessing: _self.paymentProcessing,
            confirmPaymentError: result.error
          });
          _self.loading = false;
          //console.error(error);
        } else {

          _self.$emit('paymentProcessing', {
            paymentProcessing: _self.paymentProcessing,
            orderError: []
          });

          //console.error(result)
          _self.setupIntent = result.setupIntent;
          _self.cartItems.setupIntent = _self.setupIntent;
          _self.confirmPaymentError = null;

          _self.loading = false;
          // Your customer will be redirected to your `return_url`. For some payment
        }

      });


    },
    async getPaymentMethods() {
      this.loading = true;
      await this.$http.get('api/stripe/paymentMethods')
          .then(({data}) => {
            this.listPaymentMethods = data.data;
          })
          .catch(error => {
            this.error = error
          });
      this.loading = false;
    },
    //
  },
  computed: {}
}
</script>
