<template>
  <section class="tw-mt-8 sm:tw-mt-16">
    <div class="tw-max-w-9xl tw-mx-auto tw-flex-col sm:tw-flex-row tw-space-y-6 sm:tw-space-y-0 tw-px-4 sm:tw-px-8 lg:tw-px-16 tw-flex tw-justify-between tw-pt-5 sm:tw-pt-10">
      <div class="tw-flex tw-flex-col">
        <h3 class="tw-font-serif tw-text-red tw-text-2rem tw-mb-4">Mango & Lola</h3>
        <a class="tw-text-gray-n3 tw-text-xs tw-mb-2" href="tel:(978) 678-5683">(978) 678-LOVE</a>
        <a class="tw-text-gray-n3 tw-text-xs" href="mailto:info@mangolola.com">info@mangolola.com</a>
        <div class="tw-flex tw-space-x-2 tw-mt-10">
          <a href="https://www.facebook.com/Mango-Lola-Inc-108925515052714" target="_blank" class="tw-text-gray-n3">
            <span class="facebook tw-cursor-pointer tw-h-8 tw-w-8 tw-inline-flex tw-justify-center tw-items-center tw-rounded-full">
                  <facebook-icon class="tw-w-5 tw-h-5" />
            </span>
          </a>
          <a href="https://www.instagram.com/mangololainc" target="_blank" class="tw-text-gray-n3">
            <span class="instagram tw-cursor-pointer tw-bg-gray-n3 tw-h-8 tw-w-8 tw-inline-flex tw-justify-center tw-items-center tw-rounded-full tw-text-white">
              <instagram-icon class="tw-w-5 tw-h-5" />
            </span>
          </a>
        </div>
        <div class="tw-mt-4 tw-text-xs tw-text-gray-n3">
          All are welcome here! ❤️
        </div>
      </div>
      <div class="tw-flex tw-space-x-6 sm:tw-space-x-16 tw-leading-relaxed tw-text-sm">
        <div class="tw-flex tw-flex-col tw-space-y-1">
          <router-link class="tw-text-black" :to="{ name: 'about' }">{{ $t('footer.about') }} <span class="tw-font-serif tw-text-red">Mango & Lola</span></router-link>
<!--          <router-link class="tw-text-black" :to="{ name: 'faq'}">{{ $t('footer.faq') }}</router-link>--><!--TODO: meet the team-->
          <router-link class="tw-text-black" :to="{ name: 'faq'}">{{ $t('footer.faq') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'feed' }">{{ $t('footer.wedding_offers') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'usersintro' }">{{ $t('footer.couples') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'vendorsintro' }">{{ $t('footer.vendors') }}</router-link>
          <router-link class="tw-text-black sm:tw-hidden" :to="{ name: 'investorsRelations' }">{{ $t('footer.investor_relations') }}</router-link>
        </div>
        <div class="tw-flex-col tw-space-y-1 tw-hidden sm:tw-flex">
          <router-link class="tw-text-black" :to="{ name: 'investorsRelations' }">{{ $t('footer.investor_relations') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'jobs'}">{{ $t('footer.jobs') }}</router-link>
        </div>
        <div class="tw-hidden sm:tw-flex tw-flex-col tw-space-y-1">
          <router-link class="tw-text-black" :to="{ name: 'marketing'}">{{ $t('footer.marketing') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'contactUs'}">{{ $t('footer.contact_us') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'legal'}">{{ $t('footer.legal') }}</router-link>
        </div>
        <div class="sm:tw-hidden tw-flex tw-flex-col tw-space-y-1">
          <router-link class="tw-text-black" :to="{ name: 'terms.and.conditions'}">{{ $t('footer.terms_and_conditions') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'privacy.policy'}">{{ $t('footer.privacy_policy') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'jobs'}">{{ $t('footer.jobs') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'marketing'}">{{ $t('footer.marketing') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'contactUs'}">{{ $t('footer.contact_us') }}</router-link>
          <router-link class="tw-text-black" :to="{ name: 'legal'}">{{ $t('footer.legal') }}</router-link>
        </div>
      </div>
      <router-link class="tw-inline-flex tw-no-underline tw-self-start tw-items-center tw-px-14 tw-py-4 tw-border tw-bg-red tw-leading-4 tw-font-semibold tw-rounded-md tw-text-white" :to="{ name: 'feed' }">
        {{ $t('footer.start_exploring') }}
      </router-link>

    </div>
    <p class="tw-mt-8 tw-mb-0 tw-mx-auto tw-leading-relaxed tw-text-center tw-bg-gray-n0 tw-py-4">
      © 2021 – <span class="tw-font-serif tw-text-red">Mango & Lola</span>, Inc.™ – Made with 💜 in Boston, MA
    </p>
  </section>
</template>


<script>
import { InstagramIcon, FacebookIcon } from "vue-feather-icons";

export default {
  name: 'LandingFooter',
  components:{
    InstagramIcon,
    FacebookIcon,
  }
}
</script>
<style scoped>
.instagram {
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, .25);
}
.facebook {
  background: #3B5998;
  color: white;
}
</style>
